import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
// import { firebase } from '@firebase/app';
// import * as auth from 'firebase/auth';
// import { auth } from 'firebase';
// import * as firebase from 'firebase/app';
import * as firebase from 'firebase/app'; import 'firebase/auth';
import { UserDataService } from './user-data.service';
import { AlertsService } from './alerts.service';
import { Router } from '@angular/router';
import { environment } from "../environments/environment";
import axios from 'axios';
import moment from 'moment';
import { FirebaseDataService, Sys_log } from './firebase-data.service';
// import * as admin from 'firebase-admin';
// import  credential from './firebase_admin_credentials/hoteles-6d5d6-firebase-adminsdk-jqdno-3fadab63e0.json';

// var admin = require("firebase-admin");

// var serviceAccount = require("path/to/serviceAccountKey.json");

// admin.initializeApp({
//     credential: admin.credential.cert(serviceAccount),
//     databaseURL: "https://hoteles-6d5d6.firebaseio.com"
// });

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    secondaryAuthRef: any; // used as a secondary reference to register user and prevent auto login after register
    accountSuffix = "@hotelgrandprix.com";
    constructor(
        public fireAuth: AngularFireAuth,
        private userDataService: UserDataService,
        private alertService: AlertsService,
        private router: Router,
        private firebaseService: FirebaseDataService,
    ) {
        this.secondaryAuthRef = firebase.initializeApp(environment.firebaseConfig, "secondary");

        //     fetch('./assets/data/datajson.json').then(res => res.json())
        //         .then(json => {
        //             this.data = json;
        //         });
        // console.log(this.credentialsJson);

    }


    async ngOnInit() {
        // await this.AuthPersistence();
        console.log(this.secondaryAuthRef);
    }

    async GenerateResetPasswordLink(username: string) {
        // const link = await admin.auth().generatePasswordResetLink(username);
        // console.log(link);
    }

    // ?
    async LoginAdmin(form) {
        // const res = await firebase.auth().signInWithPopup(new firebase.auth.EmailAuthProvider);
        await this.AuthPersistence();
        const res = await firebase.auth().signInWithEmailAndPassword(form.usuario + this.accountSuffix, form.password);
        // const r = await firebase.auth().signInWithPhoneNumber("6516516515", );
        // const ad = await firebase.auth().currentUser.updatePassword()
        return res;
    }

    async ResetPasswordAdmin(username: string, oldPassword: string, newPassword: string) {
        const user = firebase.auth().currentUser;

        const credentials = await firebase.auth.EmailAuthProvider.credential(username + this.accountSuffix, oldPassword);
        const reautenticate = await firebase.auth().currentUser.reauthenticateWithCredential(credentials);
        console.log(reautenticate);

        const reset = await user.updatePassword(newPassword);
        return reset;
    }

    async UpdateEmail(newEmail: string) {
        const response = await firebase.auth().currentUser.updateEmail(newEmail);
        return response;
    }

    async LoginGuest(form) {
        // const res = await firebase.auth().signInWithPopup(new firebase.auth.EmailAuthProvider);
        await this.AuthPersistence();
        const res = await firebase.auth().signInWithEmailAndPassword(form.usuario, form.password);
        // const r = await firebase.auth().signInWithPhoneNumber("6516516515", );
        // const ad = await firebase.auth().currentUser.updatePassword()
        return res;
    }


    async ResetPasswordGuest(email: string, oldPassword: string, newPassword: string) {
        const user = firebase.auth().currentUser;

        const credentials = await firebase.auth.EmailAuthProvider.credential(email, oldPassword);
        const reautenticate = await firebase.auth().currentUser.reauthenticateWithCredential(credentials);
        console.log(reautenticate);

        const reset = await user.updatePassword(newPassword);
        return reset;
    }


    // ? called in app.component.ts
    async AuthPersistence() {
        // await firebase.auth().setPersistence('local');
        // await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        // await this.fireAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        await this.fireAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
        console.log('Persistance Auth to SESSION');
    }

    async GetCurrentUser(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.fireAuth.authState.subscribe(data => {
                // console.log(data);
                if (data) {
                    resolve(data)
                } else {
                    resolve(false);
                }
            })
        });
        // console.log(firebase.auth().currentUser);
    }

    async RegisterUserAdmin(form) {

        // ? Prevent auto login (kicks off current user and logs with new one)
        const res = await this.secondaryAuthRef.auth().createUserWithEmailAndPassword(form.usuario + this.accountSuffix, form.password); // issue of register auto logged in
        // console.log(res);
        //I don't know if the next statement is necessary 
        await this.secondaryAuthRef.auth().signOut();
        return res;


        // ? common used register to login automatically after register
        // const res = await firebase.auth().createUserWithEmailAndPassword(form.email, form.password);
        // const res = await firebase.auth().createUserWithEmailAndPassword(form.usuario + this.accountSuffix, form.password);
        // return res;


        // const res = await firebase.auth().createUserWithEmailAndPassword(form.usuario + this.accountSuffix, form.password);
        // const res = await firebase.auth().createUserWithEmailAndPassword(form.email, form.password);
        // return res;
    }

    async RegisterUserGuest(form) {
        // ? common used register to login automatically after register
        const res = await firebase.auth().createUserWithEmailAndPassword(form.email, form.password);
        // const res = await firebase.auth().createUserWithEmailAndPassword(form.usuario + this.accountSuffix, form.password);
        return res;


        // const res = await firebase.auth().createUserWithEmailAndPassword(form.usuario + this.accountSuffix, form.password);
        // const res = await firebase.auth().createUserWithEmailAndPassword(form.email, form.password);
        // return res;
    }

    async Logout() {
        const response = await axios.get("https://ipinfo.io/json?token=be017536ee50f7");

        const timeStamp = moment().format('YYYY-MM-DD HH:mm:ss') + ":" + moment().millisecond();
        let sys_log: Sys_log
        if (this.userDataService.isAdmin) {
            sys_log = {
                nombre: "Logout",
                fecha: timeStamp,
                usu_id: this.userDataService.currentUser.uid,
                datos_nuevos: ['nombre: ' + this.userDataService.currentUser.nombre, 'username: ' + this.userDataService.currentUser.usuario, 'id: ' + this.userDataService.currentUser.id, response.data],
                datos_viejos: [],
                movimiento: "Cerrar session",
                idReservacion: ''
            };
        } else {
            sys_log = {
                nombre: "Logout",
                fecha: timeStamp,
                usu_id: this.userDataService.guestUser.uid,
                datos_nuevos: ['nombre: ' + this.userDataService.guestUser.name, 'email: ' + this.userDataService.guestUser.email, 'id: ' + this.userDataService.guestUser.id, response.data],
                datos_viejos: [],
                movimiento: "Cerrar session",
                idReservacion: ''
            };
        }
        await this.firebaseService.AddSys_log(sys_log);

        await this.fireAuth.signOut();
        this.userDataService.SignOut();
        this.alertService.presentToast('Cerraste Sesión');
        this.router.navigate(['/login']);
    }


    async LogOutAuth() {
        await this.fireAuth.signOut();
    }

    async GoogleLogin() {
        // await this.fireAuth.signInWithRedirect(new firebase.auth.GoogleAuthProvider);
        // const data = await this.fireAuth.getRedirectResult();
        // console.log(data);
        const data = await this.fireAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider);
        return data;
    }

    async FacebookLogin() {
        const data = await this.fireAuth.signInWithPopup(new firebase.auth.FacebookAuthProvider);
        return data;
    }



    // ? Guard login prevent to go to pages if not logged in
    //     import { AngularFireAuthGuard } from '@angular/fire/auth-guard';

    // export const routes: Routes = [
    //     { path: '',      component: AppComponent },
    //     { path: 'items', component: ItemListComponent, canActivate: [AngularFireAuthGuard] },
    // ]

}