import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask, createUploadTask } from '@angular/fire/storage';

import { Observable, of } from 'rxjs';
import { map, take, observeOn, switchMap, single, first } from 'rxjs/operators';

import * as moment from 'moment';
import { Permisos } from 'src/shared/permissions.interface';
import { ParseOptions } from 'querystring';
import { stringify } from '@angular/compiler/src/util';
import { ConfigService } from './config.service';
import { ClienteERP, ProductoERP } from './erp.service';
import { analytics } from 'firebase';

export interface DatosFormulario {
  id?: string;
  area: string;
  name: string;
  idComplejo: string;
  idCuarto: string;
  questions: any[];
}

export interface TipoServicio {
  id?: string;
  name: string;
}
export interface TipoSubServicio {
  id?: string;
  name: string;
  idTipoServicio: string;
}


export interface RespuestaFormDetallado {
  id?: string;
  idFormulario: string;
  idUsuario: string;
  idComplejo: string;
  nombre: string; // con apellido
  numHabitacion: number;
  comentarios: string;
  answers: any[];
}


export interface DatosTarjeta {
  id?: string;
  nombreTarjeta: string;
  numeroTarjeta: string;
  tarjetaMes: string;
  tarjetaAnio: string;
  tarjetaCodigo: string;
  idReservacion: string;
  idUsuario: string;
  updatedAt: string;
  /**
   * Can be either admin user id or guest user id
   */
  updatedBy: string;
}


export interface Configuracion {
  id?: string;
  nombre_usuario: string;
  url_app: string;
  id_form_to_checkin: string;
  hora_checkin: string;
  check_in: boolean,
  id_form_to_checkout: string;
  hora_checkout: string;
  check_out: boolean,

  image_to_upload: string;
  selected_color: string;
  primary_color: string;
  primary_color_contrast: string;
  secondary_color: string;
  secondary_color_contrast: string;
  reservacion_completa: boolean;
  productoPropina: ProductoERP;
  productoRentaHabitacion: ProductoERP;
  publicoGeneral: ClienteERP;
  publicoGeneralExtranjero: ClienteERP;
  banco: any;
  // ? RESERVAS EN LINEA
  tipoPoliticas: any[];
  politicas: any[];
  segmento: string;
  /**
   * ID DE CUARTO
   */
  idHabitacionSinNumero: string;
}



export enum estatusReservacion {
  Activo = 'Activo',
  // Inactivo = 'Inactivo',
  Completado = 'Completado',
  Cancelado = 'Cancelado',
  CheckIn = 'Check-In',
  Checked_Out = 'Checked_Out',
}


export enum taxes {
  /**
   * Total reservacion con impuestos EJ:      
   * tarifa manual = $200         
   * total = $167.36 y totalConImpuestos = $200
   */
  ConImpuestosIncluidos = "ConImpuestosIncluidos",

  /**
  * Total reservacion sin impuestos EJ:             
  * tarifa manual = $200              
  * total = $200 y totalConImpuestos = $200
  */
  SinImpuestos = "SinImpuestos",

  /**
  * Total reservacion más impuestos EJ:           
  * tarifa manual = $200            
  * total = $200 y totalConImpuestos = $200 + todos los impuestos = $239.00
  */
  MasImpuestos = "MasImpuestos",
}

// ? NEW 
export interface Reservacion {
  id?: string;
  /**
   * ID USUARIO PARA RELACIONAR LA RESERVACION (QUE LE APAREZCA AL CLIENTE EN SU CUENTA)
   */
  idUsuario: string;
  idsComplejos: string[];
  ERPTipoPago: any;
  fechaInicio: string;
  fechaFinal: string;
  dias: number;
  adultos: number;
  ninos: number;
  total: number;
  totalConImpuestos: number;
  datosCuartos: any[]; // ? 1.2 tarifa individual

  estatusSistema: estatusSistema;
  idCreatedBy: string; // Id de usuario 
  idUpdatedBy: string; // Id de usuario 
  createdAt: string; // tiempo
  updatedAt: string; // tiempo

  // total: number;
  puntosRecompensa: number;
  nombresAdultos: any[];
  selectedTipo: any[]; // TIPO POLITICAS
  policies: any[];
  nombresMenores: any[];
  nombreContacto: string;
  alertas: boolean;
  celular: string;
  codigoPais: string;
  solicitudEspecial: string;
  numeroFolio: string;
  estatus: estatusReservacion;
  correo: string;
  check_in: boolean;
  segmento: string;
  personasExtra: any[];
  // ? 1.01
  localizador: string;
  estatusAnterior: estatusReservacion;
  id_erp: number;
  rfc: string;
  turnoId: string;
  /**
   * version = "2": tarifas por día                     
   * version = "3": ventas al ERP                 
   */
  version: string;
  // ? 1.02 used in early check in
  paidLastDay: boolean;
  originalEndDate: string;
  // ? 1.03 divide reservations
  originalReservationId: string; // (id)
  divided: number; // (++)
  /**
   *  { dividedAt: string,  dividedBy: string,  selectedNumbers: [] }
   */
  divisionRecords: any[];
  // codigoPromocional: string;
  // ? 1.04 enviar ventas al erp
  tipoVenta: number;
  ventaIds: any[] // id 15
  // ? Cerrar turnos
  diaAnterior: boolean,
  diaAnteriorFecha: string;
  // ? Huespedes hacen reservaciones
  createdByGuest: boolean;
  editedByAdmin: boolean;
  /**
   * para poder buscar por nombres,
   */
  queryNombre: string;
  /**
  * para poder buscar por numero o numeros de habitacion,
  */
  queryHabitaciones: string[];
  /**
   * Saber si metio datos de una tarjeta
   */
  registeredCard: boolean;
  cardInfoValidated: boolean;
  // ? cuenta sin habitacion
  cuentaSinHabitacion: boolean;
  queryHuespedes: string[];
}

export interface Pago {
  id?: string;
  nombre: string;
  idsComplejos: string[];
  typeRoom: string;
  param100: number;
  fechaInicio: string;
  fechaFinal: string;
  dias: number[];
  independientes: boolean;
}

export interface PrecioCuartoFecha {
  id?: string;
  fechaInicio: string;
  fechaFinal: string;
  costoBase: number;
  tipoCuartoCalcular: string;

}

export interface FechasPagoGeneral {
  id?: string;
  lunesgral: number;
  martesgral: number;
  miercolesgral: number;
  juevesgral: number;
  viernesgral: number;
  sabadogral: number;
  domingogral: number;
}



export interface Cuarto {
  id?: string;
  complexId: string;
  typeRoomId: string;
  servicesIds: string[];
  name: string;
  description: string;
  // roomNumber: number;
  bedQuantity: number;
  bedTypes: any[];
  roomQuantity: number;
  cost: number;
  costPerPerson: number;
  personCapacity: number;
  urls: string[]; // photos urls
  productoERP: ProductoERP;
  productoPersonaExtraERP: ProductoERP;
  fakeRoom: boolean;
  estatus?: string;
}

export interface Sys_log {
  //ID(autoincrementable), nombre o ID del reporte, Fecha y hora de la consulta y quien lo consultó
  id?: string;
  nombre: string;
  fecha: string;
  movimiento: string;
  datos_nuevos: any[];
  datos_viejos: any[];
  usu_id: string;
  idReservacion: string;
}

export interface TarifaPeriodoUsuarios {
  //ID(autoincrementable), nombre o ID del reporte, Fecha y hora de la consulta y quien lo consultó
  id?: string;
  precio: number;
  descripcion: string;
  idCreatedBy: string;
  idUpdatedBy: string;
  createdAt: string;
  updatedAt: string;
  estatus: estatusSistema;
  fechaInicio: string;
  fechaFinal: string;
  dropAt: string,
  idDropBy: string,
}

export interface TarifaPeriodoUsuariosDetalle {
  //ID(autoincrementable), nombre o ID del reporte, Fecha y hora de la consulta y quien lo consultó
  id?: string;
  idConfiguracion: string;
  idCreatedBy: string;
  idUpdatedBy: string;
  createdAt: string;
  updatedAt: string;
  estatus: estatusSistema;
  cuarto_id: string;
  dropAt: string,
  idDropBy: string,
  cuartos: number
}

export interface BloqueoHabitacionesPeriodos {
  //ID(autoincrementable), nombre o ID del reporte, Fecha y hora de la consulta y quien lo consultó
  id?: string;
  descripcion: string;
  idCreatedBy: string;
  idUpdatedBy: string;
  createdAt: string;
  updatedAt: string;
  estatus: estatusSistema;
  fechaInicio: string;
  fechaFinal: string;
  dropAt: string,
  idDropBy: string,
}

export interface BloqueoHabitacionesPeriodosDetalle {
  //ID(autoincrementable), nombre o ID del reporte, Fecha y hora de la consulta y quien lo consultó
  id?: string;
  idConfiguracion: string;
  idCreatedBy: string;
  idUpdatedBy: string;
  createdAt: string;
  updatedAt: string;
  estatus: estatusSistema;
  numero_cuarto: string;
  numero_cuarto_id: string;
  tipo_cuarto_id: string;
  cuarto_id: string;
  complejo_id: string;
  dropAt: string,
  idDropBy: string,
}

export interface BloqueoHabitacionesPeriodosUsuarios {
  //ID(autoincrementable), nombre o ID del reporte, Fecha y hora de la consulta y quien lo consultó
  id?: string;
  descripcion: string;
  idCreatedBy: string;
  idUpdatedBy: string;
  createdAt: string;
  updatedAt: string;
  estatus: estatusSistema;
  fechaInicio: string;
  fechaFinal: string;
  dropAt: string,
  idDropBy: string,
}

export interface BloqueoHabitacionesPeriodosDetalleUsuarios {
  //ID(autoincrementable), nombre o ID del reporte, Fecha y hora de la consulta y quien lo consultó
  id?: string;
  idConfiguracion: string;
  idCreatedBy: string;
  idUpdatedBy: string;
  createdAt: string;
  updatedAt: string;
  estatus: estatusSistema;
  numero_cuarto: string;
  numero_cuarto_id: string;
  tipo_cuarto_id: string;
  cuarto_id: string;
  complejo_id: string;
  dropAt: string,
  idDropBy: string,
}

export interface Complejo {
  id?: string;
  name: string;
  description: string;
  servicesIds: string[];
  longitude: string;
  latitude: string;
  url: string; // photos urls
  idSucursalERP: any;
}


export interface TipoCuarto {
  id?: string;
  nombre: string;
  descripcion: string;
  complejoId: string;
  estatus?: string;
}

export interface Formulariobm {
  id?: string;
  name: string;
  description: string;
}
export interface RespuestaForm {
  id?: string;
  id_cliente: string;
  id_form: string;
  aprovacion: number;
  fecha: string;
}

export interface Servicio {
  id?: string;
  nombre: string;
  descripcion: string;
  iconoEscrito: boolean; // Si es verdadero utiliza icono de los iconos de ionic, si no, url de imagen para icono
  url: string;
  icono: string;
}

export interface Cliente {
  id?: string;
  nombre: string;
  descripcion: string;
  correo: string;
  rfc: string;
  tipo_cliente: string;
  cp: string;
  colonia: string;
  calle_num: string;
  curp: string;

}


export interface CheckIn {
  id?: string;
  llegada: Date;
  grupo: string;
  idUsuario: string;
  idReservacion: string;
  folio: string;
  salida: Date;
  clave: string;
  segmento: string;
  nombresAdultos: any[];
  nombresMenores: any[];
  // numerosCuartos: any[];
  // huespedAdulto2: string;
  menores: string;
  reqFactura: boolean;
  razonSocial: string;
  rfc: string;
  calle: string;
  nunExt: string;
  numInt: string;
  colonia: string;
  ciudad: string;
  alcaldia: string;
  estado: string;
  pais: string;
  codigoPostal: string;
  correoElectronico: string;
  telefono: string;
  procedencia: string;
  nacionalidad: string;
  ocupacion: string;
  serviciosIncluidos: string;
  numNoches: number;
  habitacion: string;
  tarifa: string;
  numAdultos: number;
  numMenores: number;
  formaPago: string;
  cambioHabitacion1: string;
  cambioHabitacion2: string;
  recepcionista: string;
  folioAeropuerto: string;
  vendedorAereopuerto: string;
  urlIne1: string;
  urlIne2: string;

  // ? Datos para auditoria y ID's:
  idERP?: string;
  estatus: estatusSistema;
  idCreatedBy: string; // Id de usuario 
  idUpdatedBy: string; // Id de usuario 
  createdAt: string; // tiempo
  updatedAt: string; // tiempo
  turnoId: string;

  // ? Cerrar Turnos
  diaAnterior: boolean;
  diaAnteriorFecha: string;

  createdByGuest: boolean;
}

export interface NumeroCuarto {
  id?: string;
  numeros: string[];
  edificios: string[];
  pisos: string[];
  activo: boolean[];
  estatus: string[];
  // metaData: [
  //   numero: string,
  //   edificio: string,
  //   piso: string,
  //   disponible: boolean
  // ];
  idCuarto: string;
  fakeRoomNumber: boolean;
}

export enum estatusTransporte {
  EnProgreso = 'EnProgreso',
  Completado = 'Completado',
  Cancelado = 'Cancelado'
}

export interface SolicitudTransporte {
  id?: string;
  idConductores: string[];
  idReservacion: string;
  estatus: estatusTransporte;
  direccion: string;
  fechaHora: string;
  nombrePersona: string;
  numeroPersonas: string;
  solicitadoPor: string;
  fechaSolicitud: string;
}

export interface Conductor {
  id?: string;
  idComplejo: string;
  nombre: string;
  matricula: string;
  disponible: boolean;
  vehiculo: string;
}

export enum estatusSistema {
  Activo = 'Activo',
  Inactivo = 'Inactivo',
}

export interface UserAdmin {
  id?: string;
  uid?: string;
  /**
   * ID DE EMPLEADO DEL ERP
   */
  idERP?: number;
  idSucursalERP: number;
  idSectorERP: number;

  estatus: estatusSistema;
  idCreatedBy: string; // Id de usuario 
  idUpdatedBy: string; // Id de usuario 
  createdAt: string; // tiempo
  updatedAt: string; // tiempo

  permisos: Permisos;
  usuario: string;
  // password: string;
  nombre: string;
  puesto: string;
  telefono: string;
  passwordReseted: boolean;
  // password: string;
  // role: string;
  isInOut?: boolean; // Crear Check In y Out Admin
}


export interface AdminPassword {
  id?: string;
  uid: string;
  userId: string;
  password: string; // encrypted
  updatedAt: string; // tiempo
}


export interface User {
  id?: string;
  uid?: string;
  email: string;
  name: string;
  lowerCaseName: string;
  lowerCaseEmail: string;
  cel: string;
  emailVerified: boolean;
  estatus: estatusSistema;
  createdBy: string; // Id de usuario 
  updatedBy: string; // Id de usuario 
  createdAt: string; // tiempo
  updatedAt: string; // tiempo
}

export interface UserPassword {
  id?: string;
  uid: string;
  userId: string;
  password: string; // encrypted
  updatedAt: string; // tiempo
}


// ? Datos para auditoria y ID's:
// id?: string;
// idERP?: string;
// estatus: estatusSistema;
// idCreatedBy: string; // Id de usuario 
// idUpdatedBy: string; // Id de usuario 
// createdAt: string; // tiempo
// updatedAt: string; // tiempo

export interface CheckOut {
  id?: string;
  idERP?: string;
  idReservacion: string;
  estatus: estatusSistema;
  idCreatedBy: string; // Id de usuario 
  idUpdatedBy: string; // Id de usuario 
  createdAt: string; // tiempo
  updatedAt: string; // tiempo
  createdAbonoAt: string; // tiempo

  extras: any[];
  // tipoPago: string;
  /**Total con impuesto + extras */
  total: number;

  registros: any[];
  turnoId: string;
  // ? Cerrar turnos
  diaAnterior: boolean;
  diaAnteriorFecha: string;
}

export interface Politica {
  id?: string;
  nombre: string;
  descripcion: string;
  politica: string;
  idTipoPolitica: string;
  order: number;

  estatus: estatusSistema;
  idCreatedBy: string; // Id de usuario 
  idUpdatedBy: string; // Id de usuario 
  createdAt: string; // tiempo
  updatedAt: string; // tiempo
}

export interface TipoPolitica {
  id?: string;
  nombre: string;
  descripcion: string;

  estatus: estatusSistema;
  idCreatedBy: string; // Id de usuario 
  idUpdatedBy: string; // Id de usuario 
  createdAt: string; // tiempo
  updatedAt: string; // tiempo
}


export interface Turno {
  id?: string;
  nombre: string;
  inicio: string;
  salida: string;
  madrugada: boolean;
  createdBy: string;
  createdAt: string;
  estatus: estatusSistema;
  orden: number;
}

export interface TurnoActivo {
  id?: string;
  createdAt: string;
  createdBy: string;
  turnoId: string;
  turnoActivo: boolean;
  estatus: estatusSistema;
  closedAt: string;
}

export interface DatosFacturacion {
  id?: string;
  apellidos: string;
  calle: string;
  codigo_pais: string;
  codigo_postal: string;
  entidad: string;
  idReservacion: string;
  id_codigo_pais: string;
  id_entidad: string;
  id_localidad: string;
  id_municipio: string;
  id_regimen_fiscal: string;
  localidad: string;
  municipio: string;
  nombres: string;
  numero: string;
  razon_social: string;
  regimen_fiscal: string;
  rfc: string;
  tipo_persona: string;
}

export interface TarifaPorOcupacion {
  //ID(autoincrementable), nombre o ID del reporte, Fecha y hora de la consulta y quien lo consultó
  id?: string;
  porcentaje_aumento: number;
  porcentaje_min: number;
  porcentaje_max: number;
  hora_inicial: string;
  hora_final: string;
  tipo: string;
  descripcion: string;
  idCreatedBy: string;
  idUpdatedBy: string;
  createdAt: string;
  updatedAt: string;
  estatus: estatusSistema;
  fechaInicio: string;
  fechaFinal: string;
  dropAt: string,
  idDropBy: string,
}

export interface TarifaPorOcupacionDetalle {
  //ID(autoincrementable), nombre o ID del reporte, Fecha y hora de la consulta y quien lo consultó
  id?: string;
  id_tipo_cuarto: number;
  id_cuarto: number;
  idCreatedBy: string;
  idUpdatedBy: string;
  createdAt: string;
  updatedAt: string;
  estatus: estatusSistema;
  dropAt: string,
  idDropBy: string,
  idConfiguracion: string,
  id_complejo: string,
}

@Injectable({
  providedIn: 'root'
})

export class FirebaseDataService {
  private datosTarjetaCollection: AngularFirestoreCollection<DatosTarjeta>;
  private datosTarjeta: Observable<DatosTarjeta[]>;

  private complejo: Observable<Complejo[]>;
  private complejoCollection: AngularFirestoreCollection<Complejo>;

  private formulariobm: Observable<Formulariobm[]>;
  private formulariobmCollection: AngularFirestoreCollection<Formulariobm>;
  private respuestaForm: Observable<RespuestaForm[]>;
  private respuestaFormCollection: AngularFirestoreCollection<RespuestaForm>;

  private servicio: Observable<Servicio[]>;
  private servicioCollection: AngularFirestoreCollection<Servicio>;
  private checkIn: Observable<CheckIn[]>;
  private checkInCollection: AngularFirestoreCollection<CheckIn>;

  private cliente: Observable<Cliente[]>;
  private clienteCollection: AngularFirestoreCollection<Cliente>;

  private configuracion: Observable<Configuracion[]>;
  private configuracionCollection: AngularFirestoreCollection<Configuracion>;

  private cuarto: Observable<Cuarto[]>;
  private cuartoCollection: AngularFirestoreCollection<Cuarto>;

  private sys_log: Observable<Sys_log[]>;
  private sys_logCollection: AngularFirestoreCollection<Sys_log>;

  private bloqueoHabitacionesPeriodos: Observable<BloqueoHabitacionesPeriodos[]>;
  private bloqueoHabitacionesPeriodosCollection: AngularFirestoreCollection<BloqueoHabitacionesPeriodos>;

  private bloqueoHabitacionesPeriodosDetalle: Observable<BloqueoHabitacionesPeriodosDetalle[]>;
  private bloqueoHabitacionesPeriodosDetalleCollection: AngularFirestoreCollection<BloqueoHabitacionesPeriodosDetalle>;

  private bloqueoHabitacionesPeriodosUsuarios: Observable<BloqueoHabitacionesPeriodosUsuarios[]>;
  private bloqueoHabitacionesPeriodosUsuariosCollection: AngularFirestoreCollection<BloqueoHabitacionesPeriodosUsuarios>;

  private bloqueoHabitacionesPeriodosDetalleUsuarios: Observable<BloqueoHabitacionesPeriodosDetalleUsuarios[]>;
  private bloqueoHabitacionesPeriodosDetalleUsuariosCollection: AngularFirestoreCollection<BloqueoHabitacionesPeriodosDetalleUsuarios>;

  private tarifaPeriodosUsuarios: Observable<TarifaPeriodoUsuarios[]>;
  private tarifaPeriodosUsuariosCollection: AngularFirestoreCollection<TarifaPeriodoUsuarios>;

  private tarifaPeriodosUsuariosDetalle: Observable<TarifaPeriodoUsuariosDetalle[]>;
  private tarifaPeriodosUsuariosDetalleCollection: AngularFirestoreCollection<TarifaPeriodoUsuariosDetalle>;

  private tarifaPorOcupacion: Observable<TarifaPorOcupacion[]>;
  private tarifaPorOcupacionCollection: AngularFirestoreCollection<TarifaPorOcupacion>;

  private tarifaPorOcupacionDetalle: Observable<TarifaPorOcupacionDetalle[]>;
  private tarifaPorOcupacionDetalleCollection: AngularFirestoreCollection<TarifaPorOcupacionDetalle>;

  private tipoCuarto: Observable<TipoCuarto[]>;
  private tipoCuartoCollection: AngularFirestoreCollection<TipoCuarto>;

  private reservacion: Observable<Reservacion[]>;
  private reservacionCollection: AngularFirestoreCollection<Reservacion>;

  private pago: Observable<Pago[]>;
  private pagoCollection: AngularFirestoreCollection<Pago>;

  private fechaspagogeneral: Observable<FechasPagoGeneral[]>;
  private fechaspagogeneralCollection: AngularFirestoreCollection<FechasPagoGeneral>;

  private preciocuartofecha: Observable<PrecioCuartoFecha[]>;
  private preciocuartofechaCollection: AngularFirestoreCollection<PrecioCuartoFecha>;

  private formulario: Observable<DatosFormulario[]>;
  private formularioCollection: AngularFirestoreCollection<DatosFormulario>;


  private respuestaFormD: Observable<RespuestaFormDetallado[]>;
  private respuestaFormDCollection: AngularFirestoreCollection<RespuestaFormDetallado>;

  private numeroCuarto: Observable<NumeroCuarto[]>;
  private numeroCuartoCollection: AngularFirestoreCollection<NumeroCuarto>;

  private solicitudTransporte: Observable<SolicitudTransporte[]>;
  private solicitudTransporteCollection: AngularFirestoreCollection<SolicitudTransporte>;

  private conductor: Observable<Conductor[]>;
  private conductorCollection: AngularFirestoreCollection<Conductor>;

  private tipoServicio: Observable<TipoServicio[]>;
  private tipoServicioCollection: AngularFirestoreCollection<TipoServicio>;

  private tipoSubServicio: Observable<TipoSubServicio[]>;
  private tipoSubServicioCollection: AngularFirestoreCollection<TipoSubServicio>;

  private userAdmin: Observable<UserAdmin[]>;
  private userAdminCollection: AngularFirestoreCollection<UserAdmin>;

  private checkOut: Observable<CheckOut[]>;
  private checkOutCollection: AngularFirestoreCollection<CheckOut>;

  private politicas: Observable<Politica[]>;
  private politicasCollection: AngularFirestoreCollection<Politica>;

  private tipoPoliticas: Observable<TipoPolitica[]>;
  private tipoPoliticasCollection: AngularFirestoreCollection<TipoPolitica>;

  private adminPasswordCollection: AngularFirestoreCollection<AdminPassword>;

  private userPasswordCollection: AngularFirestoreCollection<UserPassword>;


  private turnos: Observable<Turno[]>;
  private turnosCollection: AngularFirestoreCollection<Turno>;

  private datosFactruracion: Observable<DatosFacturacion[]>;
  private datosFactruracionCollection: AngularFirestoreCollection<DatosFacturacion>;


  // ¡ ---------------------- GUESTS -------------------------------------
  private usersCollection: AngularFirestoreCollection<User>;





  // private turnoActivoCollection: AngularFirestoreCollection<TurnoActivo>;
  constructor(private afs: AngularFirestore, private storage: AngularFireStorage, private config: ConfigService) {

    this.usersCollection = this.afs.collection<User>('users', ref => ref.where('estatus', '==', estatusSistema.Activo));
    this.userPasswordCollection = this.afs.collection<AdminPassword>('password_user');
    this.datosTarjetaCollection = this.afs.collection<DatosTarjeta>('datos_tarjeta');

    this.turnosCollection = this.afs.collection<Turno>('turno', ref => ref.where('estatus', '==', estatusSistema.Activo));
    this.turnos = this.turnosCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.turnosCollection = this.afs.collection<Turno>('turno', ref => ref.where('estatus', '==', estatusSistema.Activo));
    this.turnos = this.turnosCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );


    this.sys_logCollection = this.afs.collection<Sys_log>('sys_log');
    this.sys_log = this.sys_logCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.checkInCollection = this.afs.collection<CheckIn>('check_in');
    this.checkIn = this.checkInCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    // this.LoadAdminFirebase();


    this.tipoPoliticasCollection = this.afs.collection<TipoPolitica>('tipo_politicas', ref => ref.where('estatus', '==', estatusSistema.Activo));
    this.tipoPoliticas = this.tipoPoliticasCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );


    this.politicasCollection = this.afs.collection<Politica>('politicas', ref => ref.where('estatus', '==', estatusSistema.Activo).orderBy('order', 'asc'));
    this.politicas = this.politicasCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.numeroCuartoCollection = this.afs.collection<NumeroCuarto>('numero_cuarto');
    this.numeroCuarto = this.numeroCuartoCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );


    this.pagoCollection = this.afs.collection<Pago>('pago');
    this.pago = this.pagoCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );

    this.fechaspagogeneralCollection = this.afs.collection<FechasPagoGeneral>('fechaspagogeneral');
    this.fechaspagogeneral = this.fechaspagogeneralCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    )

    this.preciocuartofechaCollection = this.afs.collection<PrecioCuartoFecha>('preciocuartofecha');
    this.preciocuartofecha = this.preciocuartofechaCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    )


    this.cuartoCollection = this.afs.collection<Cuarto>('cuarto');
    this.cuarto = this.cuartoCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );


    this.bloqueoHabitacionesPeriodosCollection = this.afs.collection<BloqueoHabitacionesPeriodos>('bloqueo_habitaciones_periodos');
    this.bloqueoHabitacionesPeriodos = this.bloqueoHabitacionesPeriodosCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.bloqueoHabitacionesPeriodosDetalleCollection = this.afs.collection<BloqueoHabitacionesPeriodosDetalle>('bloqueo_habitaciones_periodos_detalle');
    this.bloqueoHabitacionesPeriodosDetalle = this.bloqueoHabitacionesPeriodosDetalleCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.bloqueoHabitacionesPeriodosUsuariosCollection = this.afs.collection<BloqueoHabitacionesPeriodosUsuarios>('bloqueo_habitaciones_periodos_usuarios');
    this.bloqueoHabitacionesPeriodosUsuarios = this.bloqueoHabitacionesPeriodosUsuariosCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.bloqueoHabitacionesPeriodosDetalleUsuariosCollection = this.afs.collection<BloqueoHabitacionesPeriodosDetalle>('bloqueo_habitaciones_periodos_detalle_usuarios');
    this.bloqueoHabitacionesPeriodosDetalleUsuarios = this.bloqueoHabitacionesPeriodosDetalleUsuariosCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.tarifaPeriodosUsuariosCollection = this.afs.collection<TarifaPeriodoUsuarios>('tarifa_periodos_usuarios');
    this.tarifaPeriodosUsuarios = this.tarifaPeriodosUsuariosCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.tarifaPeriodosUsuariosDetalleCollection = this.afs.collection<TarifaPeriodoUsuariosDetalle>('tarifa_periodos_usuarios_detalle');
    this.tarifaPeriodosUsuariosDetalle = this.tarifaPeriodosUsuariosDetalleCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.tarifaPorOcupacionCollection = this.afs.collection<TarifaPorOcupacion>('tarifa_por_ocupacion');
    this.tarifaPorOcupacion = this.tarifaPorOcupacionCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.tarifaPorOcupacionDetalleCollection = this.afs.collection<TarifaPorOcupacionDetalle>('tarifa_por_ocupacion_detalle');
    this.tarifaPorOcupacionDetalle = this.tarifaPorOcupacionDetalleCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.tipoCuartoCollection = this.afs.collection<TipoCuarto>('tipo_cuarto');
    this.tipoCuarto = this.tipoCuartoCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.complejoCollection = this.afs.collection<Complejo>('complejo');
    this.complejo = this.complejoCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.servicioCollection = this.afs.collection<Servicio>('servicio');
    this.servicio = this.servicioCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    this.clienteCollection = this.afs.collection<Cliente>('cliente');
    this.cliente = this.clienteCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data }
        });
      })
    );

    this.configuracionCollection = this.afs.collection<Configuracion>('configuracion');
    this.configuracion = this.configuracionCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
  }


  // ¡ ---------------------- GUESTS -------------------------------------


  LoadGuestFirebase(userId: string) {
    this.reservacionCollection = this.afs.collection<Reservacion>('reservacion', ref => ref.where('idUsuario', '==', userId).orderBy('createdAt', 'desc').limit(10));
    // this.reservacionCollection = this.afs.collection<Reservacion>('reservacion', ref => ref.orderBy('createdAt', 'desc').limit(9000));
    this.reservacion = this.reservacionCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

  }



  // ? SEARCH IN A QUERY LIKE 
  // https://youtu.be/sKFLI5FOOHs?t=5m52s
  //   admin
  // .database()
  // .ref('/vals')
  // .orderByChild('name')
  // .startAt('cho')
  // .endAt("cho\uf8ff") // uf8ff is one of the highest point of unicode range
  // .once('value') // make it not listen to this value on change
  // .then(c => res.send(c.val()));

  /** 
  * search for user
  * @param email username used to login
  */
  SearchGuestUser_ByEmail(email: string): Observable<User[]> {

    let tempUsers: Observable<User[]>;
    const collection = this.afs.collection<User>('users', ref => ref.orderBy('lowerCaseEmail').startAt(email).endAt(`${email}\uf8ff`));
    // console.log(collection);
    tempUsers = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return tempUsers;
  }

  /** 
* search for user
* @param cel username used to login
*/
  SearchGuestUser_ByCel(cel: string): Observable<User[]> {
    let tempUsers: Observable<User[]>;
    const collection = this.afs.collection<User>('users', ref => ref.orderBy('cel').startAt(cel).endAt(`${cel}\uf8ff`));
    // console.log(collection);
    tempUsers = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return tempUsers;
  }

  /** 
 * search for user
 * @param name is the full name of the user
 */
  SearchGuestUser_ByName(name: string): Observable<User[]> {

    let tempUsers: Observable<User[]>;
    // const collection = this.afs.collection<User>('users', ref => ref.orderBy('lowerCaseName').startAt(`\uf8ff${name}`).endAt(`${name}\uf8ff`));
    const collection = this.afs.collection<User>('users', ref => ref.orderBy('lowerCaseName').startAt(name).endAt(`${name}\uf8ff`));
    // console.log(collection);
    tempUsers = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return tempUsers;
  }


  // * ------------------------------ USERS GUEST ---------------------------------------
  // GetUsersGuest(): Observable<User[]> {
  //   return this.users;
  // }

  AddUserGuest(a: User): Promise<DocumentReference> {
    return this.usersCollection.add(a);
  }

  // & dar de baja
  // DeleteUserGuest(id: string): Promise<void> {
  //   return this.userAdminCollection.doc(id).delete();
  // }

  UpdateUserGuest(a: User): Promise<void> {
    return this.usersCollection.doc(a.id).update(a);
  }

  /** 
  * get user data by 
  * @param email  used to login
  */
  GetUserGuest_ByEmail(email: string): Observable<User[]> {
    let tempUsers: Observable<User[]>;
    const collection = this.afs.collection<User>('users', ref => ref.where('email', '==', email).where('estatus', '==', estatusSistema.Activo));
    // console.log(collection);
    tempUsers = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return tempUsers;
  }

  /** 
* get user data by username
* @param uid username used to login
*/
  GetUserGuest_ByUid(uid: string): Observable<User[]> {
    let tempUsers: Observable<User[]>;
    const collection = this.afs.collection<User>('users', ref => ref.where('uid', '==', uid).where('estatus', '==', estatusSistema.Activo));
    // console.log(collection);
    tempUsers = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return tempUsers;
  }

  async GetUserGuestBy_ID(id: string): Promise<User> {
    const col = this.afs.collection('users').doc(id);
    const data = (await col.ref.get()).data() as User;
    return data;
  }




  // GetReservationsByGuest(userId: string): Observable<Reservacion[]> {
  //   let tempReservacionCollection = this.afs.collection<Reservacion>('reservacion', ref => ref.where('idCreatedBy', '==', userId).orderBy('createdAt', 'desc').limit(10));
  //   let tempR = tempReservacionCollection.snapshotChanges().pipe(
  //     map(actions => {
  //       return actions.map(a => {
  //         const data = a.payload.doc.data();
  //         const id = a.payload.doc.id; // the document itself does not contain its Id
  //         return { id, ...data };
  //       });
  //     })
  //   );

  //   return tempR;
  // }





  // ¡ ---------------------- ADMIN -------------------------------------
  LoadAdminFirebase() {
    // this.reservacionCollection = this.afs.collection<Reservacion>('reservacion', ref => ref.orderBy('fechaInicio', 'desc').limit(100));
    this.reservacionCollection = this.afs.collection<Reservacion>('reservacion', ref => ref.orderBy('createdAt', 'desc').limit(800));//aqui, reducir?
    // this.reservacionCollection = this.afs.collection<Reservacion>('reservacion', ref => ref.orderBy('createdAt', 'desc').limit(9000));
    this.reservacion = this.reservacionCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );


    this.adminPasswordCollection = this.afs.collection<AdminPassword>('password_admin');




    this.checkOutCollection = this.afs.collection<CheckOut>('check_out', ref => ref.where('estatus', '==', estatusSistema.Activo));
    this.checkOut = this.checkOutCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );


    this.userAdminCollection = this.afs.collection<UserAdmin>('user_admin', ref => ref.where('estatus', '==', estatusSistema.Activo));
    this.userAdmin = this.userAdminCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );


    this.tipoServicioCollection = this.afs.collection<TipoServicio>('TiposServicios');
    this.tipoServicio = this.tipoServicioCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    this.tipoSubServicioCollection = this.afs.collection<TipoSubServicio>('TiposSubServicios');
    this.tipoSubServicio = this.tipoSubServicioCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.conductorCollection = this.afs.collection<Conductor>('conductor');
    this.conductor = this.conductorCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.solicitudTransporteCollection = this.afs.collection<SolicitudTransporte>('solicitud_transporte', ref => ref.orderBy('fechaSolicitud', 'desc').limit(40));
    this.solicitudTransporte = this.solicitudTransporteCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );



    this.datosTarjeta = this.datosTarjetaCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.formularioCollection = this.afs.collection<DatosFormulario>('formulario_dinamico');
    this.formulario = this.formularioCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.respuestaFormDCollection = this.afs.collection<RespuestaFormDetallado>('respuesta_form_detallado');
    this.respuestaFormD = this.respuestaFormDCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );


    this.formulariobmCollection = this.afs.collection<Formulariobm>('formularios_bm');
    this.formulariobm = this.formulariobmCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    this.configuracionCollection = this.afs.collection<Configuracion>('configuracion');
    this.configuracion = this.configuracionCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.respuestaFormCollection = this.afs.collection<RespuestaForm>('respuestaFormulario');
    this.respuestaForm = this.respuestaFormCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );


    // this.reservacionCollection = this.afs.collection<Reservacion>('reservacion', ref => ref.orderBy('fechaInicio', 'desc').limit(100));
    // this.reservacionCollection = this.afs.collection<Reservacion>('reservacion', ref => ref.orderBy('createdAt', 'desc').limit(50));
    this.reservacionCollection = this.afs.collection<Reservacion>('reservacion', ref => ref.orderBy('createdAt', 'desc').limit(50)); //Limite para que salgan ciertas reservaciones
    this.reservacion = this.reservacionCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.pagoCollection = this.afs.collection<Pago>('pago');
    this.pago = this.pagoCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );

    this.fechaspagogeneralCollection = this.afs.collection<FechasPagoGeneral>('fechaspagogeneral');
    this.fechaspagogeneral = this.fechaspagogeneralCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    )

    this.preciocuartofechaCollection = this.afs.collection<PrecioCuartoFecha>('preciocuartofecha');
    this.preciocuartofecha = this.preciocuartofechaCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    )


    this.cuartoCollection = this.afs.collection<Cuarto>('cuarto');
    this.cuarto = this.cuartoCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.sys_logCollection = this.afs.collection<Sys_log>('sys_log');
    this.sys_log = this.sys_logCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.bloqueoHabitacionesPeriodosCollection = this.afs.collection<BloqueoHabitacionesPeriodos>('bloqueo_habitaciones_periodos');
    this.bloqueoHabitacionesPeriodos = this.bloqueoHabitacionesPeriodosCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.bloqueoHabitacionesPeriodosDetalleCollection = this.afs.collection<BloqueoHabitacionesPeriodosDetalle>('bloqueo_habitaciones_periodos_detalle');
    this.bloqueoHabitacionesPeriodosDetalle = this.bloqueoHabitacionesPeriodosDetalleCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.bloqueoHabitacionesPeriodosUsuariosCollection = this.afs.collection<BloqueoHabitacionesPeriodosUsuarios>('bloqueo_habitaciones_periodos_usuarios');
    this.bloqueoHabitacionesPeriodosUsuarios = this.bloqueoHabitacionesPeriodosUsuariosCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.bloqueoHabitacionesPeriodosDetalleUsuariosCollection = this.afs.collection<BloqueoHabitacionesPeriodosDetalleUsuarios>('bloqueo_habitaciones_periodos_detalle_usuarios');
    this.bloqueoHabitacionesPeriodosDetalleUsuarios = this.bloqueoHabitacionesPeriodosDetalleUsuariosCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.complejoCollection = this.afs.collection<Complejo>('complejo');
    this.complejo = this.complejoCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.servicioCollection = this.afs.collection<Servicio>('servicio');
    this.servicio = this.servicioCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    this.clienteCollection = this.afs.collection<Cliente>('cliente');
    this.cliente = this.clienteCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data }
        });
      })
    );
  }



  // * ---------------------- SYS LOGS -------------------------------------------------

  /**
   * 
   */
  GetSysLogsByDay(date: string): Observable<Sys_log[]> {
    var tempCollection = this.afs.collection<Sys_log>('sys_log', ref => ref.where('fecha', '>=', date + " 00:00:00:000").where('fecha', '<=', date + " 23:59:59:999"));
    var temp = tempCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data }
        });
      })
    );
    return temp;
  }



  // * ------------------------------ TURNO ACTIVO --------------------------------------
  public turnoActivo: TurnoActivo;
  GetTurnoActivo(): Observable<TurnoActivo[]> {
    var tempCollection = this.afs.collection<TurnoActivo>('turno_activo', ref => ref.where('estatus', '==', estatusSistema.Activo).where('turnoActivo', '==', true));
    var temp = tempCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data }
        });
      })
    );
    return temp;
  }

  GetTurnosActivos(): Observable<TurnoActivo[]> {
    var tempCollection = this.afs.collection<TurnoActivo>('turno_activo', ref => ref.where('turnoActivo', '==', true));
    var temp = tempCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data }
        });
      })
    );
    return temp;
  }

  AddTurnoActivo(t: TurnoActivo): Promise<DocumentReference> {
    var tempCollection = this.afs.collection<TurnoActivo>('turno_activo');
    return tempCollection.add(t);
  }

  DeleteTurnoActivo(c: TurnoActivo) {
    // return this.checkOutCollection.doc(id).delete();
    // return this.adminPasswordCollection.doc(c.id).update(c);
  }

  UpdateTurnoActivo(t: TurnoActivo): Promise<void> {
    var tempCollection = this.afs.collection<TurnoActivo>('turno_activo');
    return tempCollection.doc(t.id).update(t);
  }


  // * ------------------------------ TURNO --------------------------------------

  GetTurnos(): Observable<Turno[]> {
    return this.turnos;
  }

  AddTurno(t: Turno): Promise<DocumentReference> {
    return this.turnosCollection.add(t);
  }

  DeleteTurno(c: Turno) {
    // return this.checkOutCollection.doc(id).delete();
    // return this.adminPasswordCollection.doc(c.id).update(c);
  }

  UpdateTurno(t: Turno): Promise<void> {
    return this.turnosCollection.doc(t.id).update(t);
  }




  // * ------------------------------ USER PASSWORDS --------------------------------------
  AddUserPassword(a: UserPassword): Promise<DocumentReference> {
    return this.userPasswordCollection.add(a);
  }

  DeleteUserPassword(c: UserPassword) {
    // return this.checkOutCollection.doc(id).delete();
    // return this.adminPasswordCollection.doc(c.id).update(c);
  }

  UpdateUserPassword(c: UserPassword): Promise<void> {
    return this.userPasswordCollection.doc(c.id).update(c);
  }

  GetUserPasswordBy_UserId(userId: string): Observable<UserPassword[]> {
    let temp: Observable<UserPassword[]>;

    const collection = this.afs.collection<UserPassword>('password_user', ref => ref.where('userId', '==', userId));
    temp = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return temp;
  }



  // * ------------------------------ ADMIN PASSWORDS --------------------------------------
  AddAdminPassword(a: AdminPassword): Promise<DocumentReference> {
    return this.adminPasswordCollection.add(a);
  }

  DeleteAdminPassword(c: AdminPassword) {
    // return this.checkOutCollection.doc(id).delete();
    // return this.adminPasswordCollection.doc(c.id).update(c);
  }

  UpdateAdminPassword(c: AdminPassword): Promise<void> {
    return this.adminPasswordCollection.doc(c.id).update(c);
  }

  GetAdminPasswordBy_UserId(userId: string): Observable<AdminPassword[]> {
    let temp: Observable<AdminPassword[]>;

    const collection = this.afs.collection<AdminPassword>('password_admin', ref => ref.where('userId', '==', userId));
    temp = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return temp;
  }


  // * ------------------------------ TIPO POLITICAS ---------------------------------------
  GetTipoPoliticas(): Observable<TipoPolitica[]> {
    return this.tipoPoliticas;
  }

  AddTipoPoliticas(p: TipoPolitica): Promise<DocumentReference> {
    return this.tipoPoliticasCollection.add(p);
  }

  DeleteTipoPoliticas(p: TipoPolitica): Promise<void> {
    // return this.checkOutCollection.doc(id).delete();
    p.estatus = estatusSistema.Inactivo;
    return this.tipoPoliticasCollection.doc(p.id).update(p);
  }

  UpdateTipoPoliticas(c: TipoPolitica): Promise<void> {
    return this.tipoPoliticasCollection.doc(c.id).update(c);
  }


  // * ------------------------------ POLITICAS ---------------------------------------
  GetPoliticas(): Observable<Politica[]> {
    return this.politicas;
  }

  GetPolitica(id: string): Observable<Politica[]> {
    let tempPolitica: Observable<Politica[]>;
    const collection = this.afs.collection<Politica>("politicas", ref => ref.where("id", "==", id));
    // console.log(collection);
    tempPolitica = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempPolitica;



  }

  AddPoliticas(p: Politica): Promise<DocumentReference> {
    return this.politicasCollection.add(p);
  }

  DeletePoliticas(p: Politica): Promise<void> {
    // return this.checkOutCollection.doc(id).delete();
    p.estatus = estatusSistema.Inactivo;
    return this.politicasCollection.doc(p.id).update(p);
  }

  UpdatePoliticas(c: Politica): Promise<void> {
    return this.politicasCollection.doc(c.id).update(c);
  }



  // * ------------------------------ CHECK-OUT ---------------------------------------
  GetCheckOut(): Observable<CheckOut[]> {
    return this.checkOut;
  }

  GetCheckOutAll(meses){
    let nowM = moment().subtract(meses, 'M').format('YYYY-MM-DD');
    let now = moment().add(1, 'd').format('YYYY-MM-DD');
    return this.afs.collection('check_out').ref.where('createdAbonoAt', '>=', nowM + "00:00").where('createdAbonoAt', '<=', now + "23:59").get();
  }

  GetCheckOutAllO(): Observable<CheckOut[]> {//Aqui?
    let tempData: Observable<CheckOut[]>;
    const collection = this.afs.collection<CheckOut>("check_out");
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.filter(act => act.payload.doc.metadata.fromCache === false).map(a => {
        // return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
  }

  GetCheckOut_ByIdReservacion(idReservacion: string): Observable<CheckOut[]> {
    let tempData: Observable<CheckOut[]>;
    const collection = this.afs.collection<CheckOut>("check_out", ref => ref.where("idReservacion", "==", idReservacion));
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
  }


  AddCheckOut(a: CheckOut): Promise<DocumentReference> {
    return this.checkOutCollection.add(a);
  }

  DeleteCheckOut(c: CheckOut): Promise<void> {
    // return this.checkOutCollection.doc(id).delete();
    c.estatus = estatusSistema.Inactivo;
    return this.checkOutCollection.doc(c.id).update(c);
  }

  UpdateCheckOut(c: CheckOut): Promise<void> {
    return this.checkOutCollection.doc(c.id).update(c);
  }

  GetCheckOutBy_idReservation(idReserv: string): Observable<CheckOut[]> {
    let temp: Observable<CheckOut[]>;

    const collection = this.afs.collection<CheckOut>('check_out', ref => ref.where('idReservacion', '==', idReserv));
    temp = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return temp;
  }

  GetCheckOuts_ByDate(startDate, endDate){
    return this.afs.collection('check_out').ref.where('createdAt', '>=', startDate).where('createdAt', '<=', endDate).get();
  }

  GetCheckOuts_ByDateO(startDate: string, endDate: string): Observable<CheckOut[]> {//Aqui?
    let tempData: Observable<CheckOut[]>;
    const collection = this.afs.collection<CheckOut>('check_out', ref => ref.where('createdAt', '>=', startDate).where('createdAt', '<=', endDate));
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        // return actions.map(a => {
        return actions.filter(act => act.payload.doc.metadata.fromCache === false).map(a => {
          const data = a.payload.doc.data() as CheckOut;
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { uid: id, ...data };
        });
      })
    );
    return tempData;
  }

  // * ------------------------------ USERS ADMIN ---------------------------------------
  GetUsersAdmin(): Observable<UserAdmin[]> {
    return this.userAdmin;
  }

  AddUserAdmin(a: UserAdmin): Promise<DocumentReference> {
    return this.userAdminCollection.add(a);
  }

  // & dar de baja
  // DeleteUserAdmin(id: string): Promise<void> {
  //   return this.userAdminCollection.doc(id).delete();
  // }

  UpdateUserAdmin(a: UserAdmin): Promise<void> {
    return this.userAdminCollection.doc(a.id).update(a);
  }

  /** 
  * get user data by username
  * @param username username used to login
  */
  GetUserAdmin_ByUsername(username: string): Observable<UserAdmin[]> {
    let tempUsers: Observable<UserAdmin[]>;
    const collection = this.afs.collection<UserAdmin>('user_admin', ref => ref.where('usuario', '==', username));
    // console.log(collection);
    tempUsers = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return tempUsers;
  }

  GetAnyUsers(): Observable<UserAdmin[]> {
    let tempUsers: Observable<UserAdmin[]>;
    const collection = this.afs.collection<UserAdmin>('user_admin');
    // console.log(collection);
    tempUsers = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return tempUsers;
  }

  /** 
* get user data by username
* @param uid username used to login
*/
  GetUserAdmin_ByUid(uid: string): Observable<UserAdmin[]> {
    let tempUsers: Observable<UserAdmin[]>;
    const collection = this.afs.collection<UserAdmin>('user_admin', ref => ref.where('uid', '==', uid).where('estatus', '==', estatusSistema.Activo));
    // console.log(collection);
    tempUsers = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return tempUsers;
  }

  async GetUserAdminBy_ID(id: string): Promise<UserAdmin> {
    const col = this.afs.collection('user_admin').doc(id);
    const data = (await col.ref.get()).data() as UserAdmin;
    return data;
  }

  async GetUserBy_ID(id: string): Promise<User> {
    const col = this.afs.collection('users').doc(id);
    const data = (await col.ref.get()).data() as User;
    return data;
  }


  /**
* @param id id
*/
  // GetSolicitudTransporte_By_X(idCuarto: string): Observable<SolicitudTransporte[]> {
  // let tempData: Observable<NumeroCuarto[]>;
  // const collection = this.afs.collection<NumeroCuarto>('numero_cuarto', ref => ref.where('idCuarto', '==', idCuarto));
  // tempData = collection.snapshotChanges().pipe(
  //   map(actions => {
  //     return actions.map(a => {
  //       const data = a.payload.doc.data() as NumeroCuarto;
  //       const id = a.payload.doc.id; // the document itself does not contain its Id
  //       return { id: id, ...data };
  //     });
  //   })
  // );
  // return tempData;
  // }



  // * ------------------------------ tipo servicio ---------------------------------------
  GetTipoServicios(): Observable<TipoServicio[]> {
    return this.tipoServicio;
  }
  GetTipoSubServiciosByTipoServicio(id: string): Observable<TipoSubServicio[]> {
    let tempData: Observable<TipoSubServicio[]>;
    const collection = this.afs.collection<TipoSubServicio>('TiposSubServicios', ref => ref.where('idTipoServicio', '==', id));
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as TipoSubServicio;
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id: id, ...data };
        });
      })
    );
    return tempData;
  }
  AddTipoServicio(c: TipoServicio): Promise<DocumentReference> {
    return this.tipoServicioCollection.add(c);
  }
  AddTipoSubServicio(c: TipoSubServicio): Promise<DocumentReference> {
    return this.tipoSubServicioCollection.add(c);
  }
  DeleteTipoServicio(id: string): Promise<void> {
    return this.tipoServicioCollection.doc(id).delete();
  }

  UpdateTipoServicio(c: TipoServicio): Promise<void> {
    return this.tipoServicioCollection.doc(c.id).update(c);
  }
  DeleteTipoSubServicio(id: string): Promise<void> {
    return this.tipoSubServicioCollection.doc(id).delete();
  }

  UpdateTipoSubServicio(c: TipoSubServicio): Promise<void> {
    return this.tipoSubServicioCollection.doc(c.id).update(c);
  }


  // * ------------------------------ CONDUCTOR ---------------------------------------
  GetConductores(): Observable<Conductor[]> {
    return this.conductor;
  }

  AddConductor(c: Conductor): Promise<DocumentReference> {
    return this.conductorCollection.add(c);
  }

  DeleteConductor(id: string): Promise<void> {
    return this.conductorCollection.doc(id).delete();
  }

  UpdateConductor(c: Conductor): Promise<void> {
    return this.conductorCollection.doc(c.id).update(c);
  }

  /**
* @param id id 
*/
  // GetSolicitudTransporte_By_X(idCuarto: string): Observable<SolicitudTransporte[]> {
  // let tempData: Observable<NumeroCuarto[]>;
  // const collection = this.afs.collection<NumeroCuarto>('numero_cuarto', ref => ref.where('idCuarto', '==', idCuarto));
  // tempData = collection.snapshotChanges().pipe(
  //   map(actions => {
  //     return actions.map(a => {
  //       const data = a.payload.doc.data() as NumeroCuarto;
  //       const id = a.payload.doc.id; // the document itself does not contain its Id
  //       return { id: id, ...data };
  //     });
  //   })
  // );
  // return tempData;
  // }






  // * ------------------------------ SOLICITUD TRANSPORTE ---------------------------------------
  GetSolicitudTransporte(): Observable<SolicitudTransporte[]> {
    return this.solicitudTransporte;
  }

  AddSolicitudTransporte(s: SolicitudTransporte): Promise<DocumentReference> {
    return this.solicitudTransporteCollection.add(s);
  }

  DeleteSolicitudTransporte(id: string): Promise<void> {
    return this.solicitudTransporteCollection.doc(id).delete();
  }

  UpdateSolicitudTransporte(s: SolicitudTransporte): Promise<void> {
    return this.solicitudTransporteCollection.doc(s.id).update(s);
  }

  /**
* @param idCuarto id reservacion
*/
  // GetSolicitudTransporte_By_X(idCuarto: string): Observable<SolicitudTransporte[]> {
  // let tempData: Observable<NumeroCuarto[]>;
  // const collection = this.afs.collection<NumeroCuarto>('numero_cuarto', ref => ref.where('idCuarto', '==', idCuarto));
  // tempData = collection.snapshotChanges().pipe(
  //   map(actions => {
  //     return actions.map(a => {
  //       const data = a.payload.doc.data() as NumeroCuarto;
  //       const id = a.payload.doc.id; // the document itself does not contain its Id
  //       return { id: id, ...data };
  //     });
  //   })
  // );
  // return tempData;
  // }



  // id numero cuarto // KeBQbpOC75bOh60ofPv6
  // * ------------------------------ NUMERO CUARTO ---------------------------------------

  /**
   * USADO PARA OBTENER EL NUMERO CUARTO (SIN HABITACIÓN)
   */
  GetFakeRoomNumber(): Observable<NumeroCuarto[]> {
    const collection = this.afs.collection<NumeroCuarto>('numero_cuarto', ref => ref.where('fakeRoomNumber', '==', true));

    const temp = collection.snapshotChanges().pipe(
      first(),
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    return temp;
  }

  GetNumeroCuarto(): Observable<NumeroCuarto[]> {
    return this.numeroCuarto;
  }

  AddNumeroCuarto(n: NumeroCuarto): Promise<DocumentReference> {
    return this.numeroCuartoCollection.add(n);
  }

  DeleteNumeroCuarto(id: string): Promise<void> {
    return this.numeroCuartoCollection.doc(id).delete();
  }


  UpdateNumeroCuarto(n: NumeroCuarto): Promise<void> {
    return this.numeroCuartoCollection.doc(n.id).update(n);
  }

  /**
* @param idCuarto id reservacion
*/
  GetNumeroCuarto_ByIdCuarto(idCuarto: string): Observable<NumeroCuarto[]> {
    let tempData: Observable<NumeroCuarto[]>;
    const collection = this.afs.collection<NumeroCuarto>('numero_cuarto', ref => ref.where('idCuarto', '==', idCuarto));
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as NumeroCuarto;
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id: id, ...data };
        });
      })
    );
    return tempData;
  }

  async GetNumeroCuartoById(id: string): Promise<NumeroCuarto> {
    const col = this.afs.collection('numero_cuarto').doc(id);
    const data = (await col.ref.get()).data() as NumeroCuarto;
    return data;
  }



  // * ------------------------------ RESPUESTAS FORMULARIOS DINAMICOS ---------------------------------------
  GetRespuestaFormulariosDinamicos(): Observable<RespuestaFormDetallado[]> {
    return this.respuestaFormD;
  }

  AddRespuestaFormularioDinamico(r: RespuestaFormDetallado): Promise<DocumentReference> {
    return this.respuestaFormDCollection.add(r);
  }

  DeleteRespuestaFormularioDinamico(id: string): Promise<void> {
    return this.respuestaFormDCollection.doc(id).delete();
  }

  UpdateRespuestaFormularioDinamico(r: RespuestaFormDetallado): Promise<void> {
    return this.respuestaFormDCollection.doc(r.id).update(r);
  }

  async GetRespuestaFormularioDinamicoBy_ID(idRespuestaD: string): Promise<RespuestaFormDetallado> {
    const col = this.afs.collection('respuesta_form_detallado').doc(idRespuestaD);
    const data = (await col.ref.get()).data() as RespuestaFormDetallado;
    return data;
  }



  // * ------------------------------ FORMULARIOS DINAMICOS ---------------------------------------
  GetFormulariosDinamicos(): Observable<DatosFormulario[]> {
    return this.formulario;
  }

  AddFormularioDinamico(r: DatosFormulario): Promise<DocumentReference> {
    return this.formularioCollection.add(r);
  }

  DeleteFormularioDinamico(id: string): Promise<void> {
    return this.formularioCollection.doc(id).delete();
  }

  UpdateFormularioDinamico(r: DatosFormulario): Promise<void> {
    return this.formularioCollection.doc(r.id).update(r);
  }


  async GetFormularioDinamicoBy_ID(idFormulario: string): Promise<DatosFormulario> {
    const col = this.afs.collection('formulario_dinamico').doc(idFormulario);
    const data = (await col.ref.get()).data() as DatosFormulario;
    data.id = idFormulario;
    return data;
  }


  // * ------------------------------ Formulariobm---------------------------------------
  GetFormulariosbm(): Observable<Formulariobm[]> {
    return this.formulariobm;
  }


  AddDatosFormulariobm(d: Formulariobm): Promise<DocumentReference> {
    return this.formulariobmCollection.add(d);
  }

  DeleteFormulariobm(id: string): Promise<void> {
    return this.formulariobmCollection.doc(id).delete();
  }

  UpdateFormulariobm(d: Formulariobm): Promise<void> {
    return this.formulariobmCollection.doc(d.id).update(d);
  }

  GetAllFormulariobm(): Observable<Formulariobm[]> {
    let tempData: Observable<Formulariobm[]>;
    const collection = this.afs.collection<Formulariobm>('formularios_bm');
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Formulariobm;
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id: id, ...data };
        });
      })
    );
    return tempData;
  }

  GetFormulariobmByName(name: string): Observable<Formulariobm[]> {
    let tempData: Observable<Formulariobm[]>;
    const collection = this.afs.collection<Formulariobm>('formularios_bm', ref => ref.where('name', '==', name));
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Formulariobm;

          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id: id, ...data };
        });
      })
    );
    // tempData.forEach(element => {

    //   console.log("Haz pedido por "+ ( element));
    // });
    // console.log("TE ESTOY REGRESANDOO DATA "+JSON.stringify(tempData));
    return tempData;
  }

  GetFormulariobmById(idForm: string): Observable<Formulariobm[]> {
    let tempData: Observable<Formulariobm[]>;
    const collection = this.afs.collection<Formulariobm>('formularios_bm', ref => ref.where('id', '==', idForm));
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Formulariobm;

          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id: id, ...data };
        });
      })
    );
    tempData.forEach(element => {

      console.log("Haz pedido por " + (element));
    });
    console.log("TE ESTOY REGRESANDOO DATA " + (tempData));
    return tempData;
  }
  // async CreateUploadTask_Formulariobm(file: any, fullPathFile: string) {
  //   const filePathn = '/imagenes/checkIn/' + fullPathFile; // * firebase storage path here (folder{s})
  //   const ref = this.storage.ref(filePathn);
  //   const task = await ref.put(file);
  //   const surl = await task.ref.getDownloadURL();
  //   return surl;

  // }


  // * ------------------------------ CHECK IN---------------------------------------
  GetCheckIns(): Observable<CheckIn[]> {
    return this.checkIn;
  }



  AddDatosCheckIn(d: CheckIn): Promise<DocumentReference> {
    return this.checkInCollection.add(d);
  }

  DeleteCheckIn(id: string): Promise<void> {
    return this.checkInCollection.doc(id).delete();
  }

  UpdateCheckIn(d: CheckIn): Promise<void> {
    return this.checkInCollection.doc(d.id).update(d);
  }

  GetCheckIn_IdReservacion(idReservacion: string): Observable<CheckIn[]> {
    let tempData: Observable<CheckIn[]>;
    const collection = this.afs.collection<CheckIn>('check_in', ref => ref.where('idReservacion', '==', idReservacion));
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as CheckIn;
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id: id, ...data };
        });
      })
    );
    return tempData;
  }
  async CreateUploadTask_checkIn(file: any, fullPathFile: string) {
    const filePathn = '/imagenes/checkIn/' + fullPathFile; // * firebase storage path here (folder{s})
    const ref = this.storage.ref(filePathn);
    const task = await ref.put(file);
    const surl = await task.ref.getDownloadURL();
    return surl;
    // const file = event.target.files[0];
    // const filePath = '/categories_img/' + fullPathFile; // * firebase storage path here (folder{s})
    // const ref = this.storage.ref(filePath);
    // const task = ref.put(file); // * also exist putString() and upload()
    // this.progress = task.percentageChanges();
  }

  GetCheckIns_ByDate(startDate, endDate){
    return this.afs.collection('check_in').ref.where('createdAt', '>=', startDate).where('createdAt', '<=', endDate).get();
  }

  GetCheckIns_ByDateO(startDate: string, endDate: string): Observable<CheckIn[]> {//Aqui?
    let tempData: Observable<CheckIn[]>;
    const collection = this.afs.collection<CheckIn>('check_in', ref => ref.where('createdAt', '>=', startDate).where('createdAt', '<=', endDate));
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        // return actions.map(a => {
        return actions.filter(act => act.payload.doc.metadata.fromCache === false).map(a => {
          const data = a.payload.doc.data() as CheckIn;
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { uid: id, ...data };
        });
      })
    );
    return tempData;
  }

  // * ------------------------------respuestaForm---------------------------------------
  GetRespuestaForm(): Observable<RespuestaForm[]> {
    return this.respuestaForm;
  }


  AddDatosRespuestaForm(d: RespuestaForm): Promise<DocumentReference> {
    return this.respuestaFormCollection.add(d);
  }

  DeleteRespuestaForm(id: string): Promise<void> {
    return this.respuestaFormCollection.doc(id).delete();
  }

  UpdateRespuestaForm(d: RespuestaForm): Promise<void> {
    return this.respuestaFormCollection.doc(d.id).update(d);
  }

  // * ------------------------------Configuracion---------------------------------------
  GetConfiguracion(): Observable<Configuracion[]> {
    return this.configuracion;
  }


  AddDatosConfiguracion(d: Configuracion): Promise<DocumentReference> {
    return this.configuracionCollection.add(d);
  }

  DeleteConfiguracion(id: string): Promise<void> {
    return this.configuracionCollection.doc(id).delete();
  }

  UpdateConfiguracion(d: Configuracion): Promise<void> {
    return this.configuracionCollection.doc(d.id).update(d);
  }

  // GetConfiguracion_IdReservacion(idReservacion: string): Observable<Configuracion[]> {
  //   let tempData: Observable<Configuracion[]>;
  //   const collection = this.afs.collection<Configuracion>('configuracion', ref => ref.where('idReservacion', '==', idReservacion));
  //   tempData = collection.snapshotChanges().pipe(
  //     map(actions => {
  //       return actions.map(a => {
  //         const data = a.payload.doc.data() as Configuracion;
  //         const id = a.payload.doc.id; // the document itself does not contain its Id
  //         return { uid: id, ...data };
  //       });
  //     })
  //   );
  //   return tempData;
  // }
  // async CreateUploadTask_Configuracion(file: any, fullPathFile: string) {
  //   const filePathn = '/imagenes/Configuracion/' + fullPathFile; // * firebase storage path here (folder{s})
  //   const ref = this.storage.ref(filePathn);
  //   const task = await ref.put(file);
  //   const surl = await task.ref.getDownloadURL();
  //   return surl;
  //   // const file = event.target.files[0];
  //   // const filePath = '/categories_img/' + fullPathFile; // * firebase storage path here (folder{s})
  //   // const ref = this.storage.ref(filePath);
  //   // const task = ref.put(file); // * also exist putString() and upload()
  //   // this.progress = task.percentageChanges();
  // }


  // *---------------------------------------------------------- DATOS TARJETA ---------------------------------------------------------


  GetDatosTarjeta(): Observable<DatosTarjeta[]> {
    return this.datosTarjeta;
  }


  /**
  * @param idReservacion id reservacion
  */
  GetDatosTarjeta_IdReservacion(idReservacion: string): Observable<DatosTarjeta[]> {
    let tempData: Observable<DatosTarjeta[]>;
    const collection = this.afs.collection<DatosTarjeta>('datos_tarjeta', ref => ref.where('idReservacion', '==', idReservacion));

    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as DatosTarjeta;
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id: id, ...data };
        });
      })
    );
    return tempData;
  }

  /**
* @param idUsuario Id tipo de cuarto
*/
  GetDatosTarjeta_IdUsuario(idUsuario: string): Observable<DatosTarjeta[]> {
    let tempData: Observable<DatosTarjeta[]>;
    const collection = this.afs.collection<DatosTarjeta>('datos_tarjeta', ref => ref.where('idUsuario', '==', idUsuario));
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as DatosTarjeta;
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { uid: id, ...data };
        });
      })
    );
    return tempData;
  }


  /**
* @param nombreTarjeta nombre que aparece en la tarjeta
*/
  GetDatosTarjeta_NombreTarjeta(nombreTarjeta: string): Observable<DatosTarjeta[]> {
    let tempData: Observable<DatosTarjeta[]>;
    const collection = this.afs.collection<DatosTarjeta>('datos_tarjeta', ref => ref.where('nombreTarjeta', '==', nombreTarjeta));
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as DatosTarjeta;
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { uid: id, ...data };
        });
      })
    );
    return tempData;
  }

  GetDatosTarjeta_Varios(reservaciones: any): Observable<DatosTarjeta[]> {

    if(reservaciones == null || reservaciones.length == 0){
      return of([]);
    }
    let tempData: Observable<DatosTarjeta[]>;
    const collection = this.afs.collection<DatosTarjeta>('datos_tarjeta', ref => ref.where('idReservacion', 'in', reservaciones));
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as DatosTarjeta;
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { uid: id, ...data };
        });
      })
    );
    return tempData;
  }



  AddDatosTarjeta(d: DatosTarjeta): Promise<DocumentReference> {
    return this.datosTarjetaCollection.add(d);
  }

  DeleteDatosTarjeta(id: string): Promise<void> {
    return this.datosTarjetaCollection.doc(id).delete();
  }

  UpdateDatosTarjeta(d: DatosTarjeta): Promise<void> {
    return this.datosTarjetaCollection.doc(d.id).update(d);
  }


  // * ------------------------------ RESERVACION ---------------------------------------

  GetAllReservationsValues(): Observable<Reservacion[]> {
    const tempCollection = this.afs.collection<Reservacion>('reservacion', ref => ref.limit(800)).valueChanges();
    return tempCollection;

    // const tempCollection = this.afs.collection<Reservacion>('reservacion', ref => ref.orderBy('createdAt', 'desc'));
    // const temp = tempCollection.snapshotChanges().pipe(
    //   map(actions => {
    //     return actions.map(a => {
    //       const data = a.payload.doc.data();
    //       const id = a.payload.doc.id; // the document itself does not contain its Id
    //       return { id, ...data };
    //     });
    //   })
    // );
    // return temp;
  }


  // ? BUSQUEDAS QUERY DE FIREBASE
  GetReservation_ByNumeroHabitacion(numeroHabitacion: string[]): Observable<Reservacion[]> {
    const tempCollection = this.afs.collection<Reservacion>('reservacion', ref => ref.where('queryHabitaciones', 'array-contains-any', numeroHabitacion).orderBy('createdAt', 'desc'));
    const temp = tempCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return temp;
  }

  GetReservation_ByEstatus(estatus: string): Observable<Reservacion[]> {
    const tempCollection = this.afs.collection<Reservacion>('reservacion', ref => ref.where('estatus', '==', estatus).orderBy('createdAt', 'desc'));
    const temp = tempCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return temp;
  }

  /**
   * nombre huesped must be not empty string '' nor null or undefined
   */
  GetReservation_ByNombreHuesped(nombreHuesped: string[]): Observable<Reservacion[]> {
    const tempCollection = this.afs.collection<Reservacion>('reservacion', ref => ref.where('queryHuespedes', 'array-contains-any', nombreHuesped).orderBy('createdAt', 'desc'));
    const temp = tempCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return temp;
  }

  GetReservation_ByNumeroFolio(numeroFolio: string): Observable<Reservacion[]> {
    const tempCollection = this.afs.collection<Reservacion>('reservacion', ref => ref.orderBy('numeroFolio').startAt(numeroFolio).endAt(numeroFolio + '\uf8ff').limit(10));
    const temp = tempCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return temp;
  }



  GetReservation_ByCorreo(correo: string): Observable<Reservacion[]> {
    const tempCollection = this.afs.collection<Reservacion>('reservacion', ref => ref.orderBy('correo').startAt(correo).endAt(correo + '\uf8ff'));
    const temp = tempCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return temp;
  }


  GetReservation_ByNombreContacto(nombreContacto: string): Observable<Reservacion[]> {
    const tempCollection = this.afs.collection<Reservacion>('reservacion', ref => ref.orderBy('queryNombre').startAt(nombreContacto));
    const temp = tempCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return temp;
  }

  GetReservation_ByLocalizador(localizador: string): Observable<Reservacion[]> {
    const tempCollection = this.afs.collection<Reservacion>('reservacion', ref => ref.orderBy('localizador').startAt(localizador).endAt(localizador + '\uf8ff'));
    const temp = tempCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return temp;
  }

  GetReservation_ByCelular(celular: string): Observable<Reservacion[]> {
    const tempCollection = this.afs.collection<Reservacion>('reservacion', ref => ref.orderBy('celular').startAt(celular).endAt(celular + '\uf8ff'));
    const temp = tempCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return temp;
  }


  /**
   * Return reservations of all times (no limit)
   * @returns All reservations Observable
   */
  GetAllReservations(): Observable<Reservacion[]> {
    const tempCollection = this.afs.collection<Reservacion>('reservacion', ref => ref.orderBy('createdAt', 'desc'));
    const temp = tempCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return temp;
  }

  GetReservations(): Observable<Reservacion[]> {
    return this.reservacion;
  }

  AddReservation(r: Reservacion): Promise<DocumentReference> {
    return this.reservacionCollection.add(r);
  }

  DeleteReservation(id: string): Promise<void> {
    return this.reservacionCollection.doc(id).delete();
  }

  UpdateReservation(r: Reservacion): Promise<void> {
    return this.reservacionCollection.doc(r.id).update(r);
  }

  GetReservacionesAll(){
    return this.afs.collection('reservacion').ref.orderBy('createdAt', 'desc').get();
  }

  GetUsuariosAll(){
    return this.afs.collection('user_admin').ref.get();
  }

  GetLogsByIdReserv(id: string){
    return this.afs.collection('sys_log').ref.where('idReservacion', '==', id).get();
  }

  GetReservationBy_TipoCuarto(idTipoCuarto: string): Observable<Reservacion[]> {
    let tempReservation: Observable<Reservacion[]>;
    const collection = this.afs.collection<Reservacion>('reservacion', ref => ref.where('tipoCuarto', '==', idTipoCuarto));
    // console.log(collection);
    tempReservation = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Reservacion;
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id: id, ...data };
        });
      })
    );
    return tempReservation;
  }



  GetReservationBy_IdCuarto_FechFinal(idCuarto: string, currentReservation_fechaInicio: string = ''): Observable<Reservacion[]> {
    // Conditions
    // I >= start && I <= end
    let tempReservation: Observable<Reservacion[]>;
    // // * NEW 2, permitir hacer reservacion incluso con check in, solo si la reservacion con check in, termina el dia que se intenta hacer la reservacion
    // // * E.g reservacion (check in) inicio X - salida 12-11-2021, reservacion (la nueva) inicio 12-11-2021 - salida X
    // const collection = this.afs.collection<Reservacion>('reservacion', ref => ref.where('fechaFinal', '>', currentReservation_fechaInicio)
    //   .where('estatus', 'in', [estatusReservacion.Activo, estatusReservacion.Checked_Out, estatusReservacion.Completado, estatusReservacion.CheckIn])
    //   .limit(5000)
    // );
    // * NEW
    const collection = this.afs.collection<Reservacion>('reservacion', ref => ref.where('fechaFinal', '>=', currentReservation_fechaInicio)
      .where('estatus', 'in', [estatusReservacion.Activo, estatusReservacion.Checked_Out, estatusReservacion.Completado, estatusReservacion.CheckIn])
      .limit(800)
    );

    // * OLD
    // const collection = this.afs.collection<Reservacion>('reservacion', ref => ref.where('idCuarto', '==', idCuarto)
    //   .where('fechaFinal', '>=', currentReservation_fechaInicio)
    //   // .where('estatus', '!=', estatusReservacion.Cancelado)
    //   .limit(200)
    // );
    // console.log(collection);
    tempReservation = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Reservacion;
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id: id, ...data };
        });
      })
    );

    return tempReservation;
    // const tmp = await this.AwaitForData(tempReservation);
    // reservations = reservations.concat(tmp);
    // console.log(reservations);

    // return reservations;
  }

  GetReservacions_Active(): Observable<Reservacion[]> {
    // //console.log('id_' + categoryId);
    let temp: Observable<Reservacion[]>;

    const now = moment().format('YYYY-MM-DD HH:mm');
    // const collection = this.afs.collection<Pedido>('pedidos', ref => ref

    const collection = this.afs.collection<Reservacion>('reservacion', ref => ref.where('fechaFinal', '>', now)
      // .orderBy('date', 'desc').startAt(startDate).endAt(endDate));
      .orderBy('fechaFinal', 'desc'));
    // //console.log(collection);
    temp = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    // //console.log(2);

    return temp;
  }

  GetReservacionBy_DateReportes(startDate, endDate){
    return this.afs.collection('reservacion').ref.where('fechaInicio', '>=', startDate).where('fechaInicio', '<=', endDate).orderBy('fechaInicio', 'desc').get();
  }

  GetReservacionBy_DateReportesO(startDate: string, endDate: string): Observable<Reservacion[]> {
    // //console.log('id_' + categoryId);
    let temp: Observable<Reservacion[]>;
    // const collection = this.afs.collection<Pedido>('pedidos', ref => ref
    const collection = this.afs.collection<Reservacion>('reservacion', ref => ref.where('fechaInicio', '>=', startDate).where('fechaInicio', '<=', endDate)
      // .orderBy('date', 'desc').startAt(startDate).endAt(endDate));
      .orderBy('fechaInicio', 'desc'));
    // //console.log(collection);
    temp = collection.snapshotChanges().pipe(
      map(actions => {
        // actions = actions.filter(act => act.payload.doc.metadata.fromCache === false);
        // return actions.map(a => {
        return actions.filter(act => act.payload.doc.metadata.fromCache === false).map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    // //console.log(2);

    return temp;
  }

  GetReservacionBy_Date(startDate: string, endDate: string): Observable<Reservacion[]> {
    // //console.log('id_' + categoryId);
    let temp: Observable<Reservacion[]>;
    // const collection = this.afs.collection<Pedido>('pedidos', ref => ref
    const collection = this.afs.collection<Reservacion>('reservacion', ref => ref.where('fechaInicio', '>=', startDate).where('fechaInicio', '<=', endDate)
      // .orderBy('date', 'desc').startAt(startDate).endAt(endDate));
      .orderBy('fechaInicio', 'desc'));
    // //console.log(collection);
    temp = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    // //console.log(2);

    return temp;
  }

  //Angular fire 2 buscar aqui
  GetReservacionBy_Segmento(segmento: any): Observable<Reservacion[]> {
    let temp: Observable<Reservacion[]>;
    const collection = this.afs.collection<Reservacion>('reservacion', ref => ref.where('segmento', '==', segmento)
    .orderBy('createdAt', 'desc'));
    temp = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    return temp;
  }

  //Esto se usa para actualizar reservaciones con queryHuespedes
  GetReservacionBy_CreatedDate(startDate: string, endDate: string): Observable<Reservacion[]> {
    // //console.log('id_' + categoryId);
    let temp: Observable<Reservacion[]>;
    // const collection = this.afs.collection<Pedido>('pedidos', ref => ref
    const collection = this.afs.collection<Reservacion>('reservacion', ref => ref.where('createdAt', '>=', startDate).where('createdAt', '<=', endDate)
      // .orderBy('date', 'desc').startAt(startDate).endAt(endDate));
      .orderBy('createdAt', 'desc'));
    // //console.log(collection);
    temp = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    // //console.log(2);

    return temp;
  }

  //Esto se usa para actualizar reservaciones con queryHuespedes
  GetReservacionSinQueryHuesp(): Observable<Reservacion[]> {
    let temp: Observable<Reservacion[]>;
    const collection = this.afs.collection<Reservacion>('reservacion', ref => ref
      .orderBy('createdAt', 'desc'));
    temp = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    return temp;
  }

  GetReservationBy_userUID(userUid: string): Observable<Reservacion[]> {
    let tempReservation: Observable<Reservacion[]>;
    const collection = this.afs.collection<Reservacion>('reservacion', ref => ref.where('idUsuario', '==', userUid).orderBy('fechaReservacion', 'desc'));
    // console.log(collection);
    tempReservation = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Reservacion;
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { uid: id, ...data };
        });
      })
    );
    return tempReservation;
  }

  GetReservationBy_uid(uid: string): Observable<Reservacion[]> {
    let tempReservation: Observable<Reservacion[]>;
    const collection = this.afs.collection<Reservacion>('reservacion', ref => ref.where('id', '==', uid));
    // console.log(collection);
    tempReservation = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Reservacion;
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { uid: id, ...data };
        });
      })
    );
    return tempReservation;
  }

  async GetReservationsById(id: string): Promise<Reservacion> {
    const col = this.afs.collection('reservacion').doc(id);
    const data = (await col.ref.get()).data() as Reservacion;
    return data;
  }

  GetReservationsCheckIn(): Observable<Reservacion[]> {
    let tempReservation: Observable<Reservacion[]>;
    const collection = this.afs.collection<Reservacion>('reservacion', ref => ref.where('estatus', '==', estatusReservacion.CheckIn));
    tempReservation = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Reservacion;
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { uid: id, ...data };
        });
      })
    );
    return tempReservation;
  }

  GetReservationsByOriginalId(id: string): Observable<Reservacion[]> {
    let tempReservation: Observable<Reservacion[]>;
    const collection = this.afs.collection<Reservacion>('reservacion', ref => ref.where('originalReservationId', '==', id));
    tempReservation = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Reservacion;
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { uid: id, ...data };
        });
      })
    );
    return tempReservation;
  }

  // * ------------------------------ CUARTO ---------------------------------------
  /**
  * OBTENER CUARTO SIN HABITACIÓN
  */
  GetCuartoSinHabitacion(): Observable<Cuarto[]> {
    const collection = this.afs.collection<Cuarto>('cuarto', ref => ref.where('fakeRoom', '==', true));
    const temp = collection.snapshotChanges().pipe(
      first(),
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return temp;
  }
  GetCuartos(): Observable<Cuarto[]> {
    return this.cuarto;
  }

  AddCuarto(c: Cuarto): Promise<DocumentReference> {
    return this.cuartoCollection.add(c);
  }

  DeleteCuarto(id: string): Promise<void> {
    return this.cuartoCollection.doc(id).delete();
  }

  UpdateCuarto(c: Cuarto): Promise<void> {
    return this.cuartoCollection.doc(c.id).update(c);
  }

  GetCuartoByRoomTypeId(idRoomType: string): Observable<Cuarto[]> {
    let tempData: Observable<Cuarto[]>;
    const collection = this.afs.collection<Cuarto>("cuarto", ref => ref.where("typeRoomId", "==", idRoomType));
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
  }

  async GetCuartoById(id: string): Promise<Cuarto> {
    const col = this.afs.collection('cuarto').doc(id);
    const data = (await col.ref.get()).data() as Cuarto;
    return data;
  }

  // * ------------------------------ SYS_LOG ---------------------------------------
  GetSys_log(): Observable<Sys_log[]> {
    return this.sys_log;
  }

  AddSys_log(c: Sys_log): Promise<DocumentReference> {
    return this.sys_logCollection.add(c);
  }

  DeleteSys_log(id: string): Promise<void> {
    return this.sys_logCollection.doc(id).delete();
  }

  UpdateSys_log(c: Sys_log): Promise<void> {
    return this.sys_logCollection.doc(c.id).update(c);
  }


  // * ------------------------------ BloqueoHabitacionesPeriodos ---------------------------------------
  GetBloqueoHabitacionesPeriodos(): Observable<BloqueoHabitacionesPeriodos[]> {
    return this.bloqueoHabitacionesPeriodos;
  }

  AddBloqueoHabitacionesPeriodos(c: BloqueoHabitacionesPeriodos): Promise<DocumentReference> {
    return this.bloqueoHabitacionesPeriodosCollection.add(c);
  }

  DeleteBloqueoHabitacionesPeriodos(id: string): Promise<void> {
    return this.bloqueoHabitacionesPeriodosCollection.doc(id).delete();
  }

  UpdateBloqueoHabitacionesPeriodos(c: BloqueoHabitacionesPeriodos): Promise<void> {
    return this.bloqueoHabitacionesPeriodosCollection.doc(c.id).update(c);
  }

  GetBloqueoHabitacionesPeriodosActivos(): Observable<BloqueoHabitacionesPeriodos[]> {
    let tempBloqueoHabitacionesPeriodos: Observable<BloqueoHabitacionesPeriodos[]>;
    const collection = this.afs.collection<BloqueoHabitacionesPeriodos>('bloqueo_habitaciones_periodos', ref => ref.where('estatus', '==', estatusSistema.Activo));
    tempBloqueoHabitacionesPeriodos = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as BloqueoHabitacionesPeriodos;
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempBloqueoHabitacionesPeriodos;
  }

  async GetBloqueoHabitacionesPeriodosById(id: string): Promise<BloqueoHabitacionesPeriodos> {
    const col = this.afs.collection('bloqueo_habitaciones_periodos').doc(id);
    const data = (await col.ref.get()).data() as BloqueoHabitacionesPeriodos;
    return data;
  }


  // * ------------------------------ BloqueoHabitacionesPeriodosDetalle ---------------------------------------
  GetBloqueoHabitacionesPeriodosDetalle(): Observable<BloqueoHabitacionesPeriodosDetalle[]> {
    return this.bloqueoHabitacionesPeriodosDetalle;
  }

  AddBloqueoHabitacionesPeriodosDetalle(c: BloqueoHabitacionesPeriodosDetalle): Promise<DocumentReference> {
    return this.bloqueoHabitacionesPeriodosDetalleCollection.add(c);
  }

  DeleteBloqueoHabitacionesPeriodosDetalle(id: string): Promise<void> {
    return this.bloqueoHabitacionesPeriodosDetalleCollection.doc(id).delete();
  }

  UpdateBloqueoHabitacionesPeriodosDetalle(c: BloqueoHabitacionesPeriodosDetalle): Promise<void> {
    return this.bloqueoHabitacionesPeriodosDetalleCollection.doc(c.id).update(c);
  }

  GetBloqueoHabitacionesPeriodosDetalleByIdConfig(id: string): Observable<BloqueoHabitacionesPeriodosDetalle[]> {
    let tempBloqueoBloqueoHabitacionesPeriodosDetalle: Observable<BloqueoHabitacionesPeriodosDetalle[]>;
    const collection = this.afs.collection<BloqueoHabitacionesPeriodosDetalle>('bloqueo_habitaciones_periodos_detalle', ref => ref.where('idConfiguracion', '==', id));
    tempBloqueoBloqueoHabitacionesPeriodosDetalle = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as BloqueoHabitacionesPeriodosDetalle;
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempBloqueoBloqueoHabitacionesPeriodosDetalle;
  }

  GetBloqueoHabitacionesPeriodosDetalleByIdConfigActivos(id: string): Observable<BloqueoHabitacionesPeriodosDetalle[]> {
    let tempBloqueoBloqueoHabitacionesPeriodosDetalle: Observable<BloqueoHabitacionesPeriodosDetalle[]>;
    const collection = this.afs.collection<BloqueoHabitacionesPeriodosDetalle>('bloqueo_habitaciones_periodos_detalle', ref => ref.where('idConfiguracion', '==', id).where("estatus", "==", estatusSistema.Activo));
    tempBloqueoBloqueoHabitacionesPeriodosDetalle = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as BloqueoHabitacionesPeriodosDetalle;
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempBloqueoBloqueoHabitacionesPeriodosDetalle;
  }

  // * ------------------------------ BloqueoHabitacionesPeriodosUsuarios ---------------------------------------
  GetBloqueoHabitacionesPeriodosUsuarios(): Observable<BloqueoHabitacionesPeriodosUsuarios[]> {
    return this.bloqueoHabitacionesPeriodosUsuarios;
  }

  AddBloqueoHabitacionesPeriodosUsuarios(c: BloqueoHabitacionesPeriodosUsuarios): Promise<DocumentReference> {
    return this.bloqueoHabitacionesPeriodosUsuariosCollection.add(c);
  }

  DeleteBloqueoHabitacionesPeriodosUsuarios(id: string): Promise<void> {
    return this.bloqueoHabitacionesPeriodosUsuariosCollection.doc(id).delete();
  }

  UpdateBloqueoHabitacionesPeriodosUsuarios(c: BloqueoHabitacionesPeriodosUsuarios): Promise<void> {
    return this.bloqueoHabitacionesPeriodosUsuariosCollection.doc(c.id).update(c);
  }

  GetBloqueoHabitacionesPeriodosUsuariosActivos(): Observable<BloqueoHabitacionesPeriodosUsuarios[]> {
    let tempBloqueoHabitacionesPeriodosUsuarios: Observable<BloqueoHabitacionesPeriodosUsuarios[]>;
    const collection = this.afs.collection<BloqueoHabitacionesPeriodosUsuarios>('bloqueo_habitaciones_periodos_usuarios', ref => ref.where('estatus', '==', estatusSistema.Activo));
    tempBloqueoHabitacionesPeriodosUsuarios = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as BloqueoHabitacionesPeriodosUsuarios;
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempBloqueoHabitacionesPeriodosUsuarios;
  }

  async GetBloqueoHabitacionesPeriodosUsuariosById(id: string): Promise<BloqueoHabitacionesPeriodosUsuarios> {
    const col = this.afs.collection('bloqueo_habitaciones_periodos_usuarios').doc(id);
    const data = (await col.ref.get()).data() as BloqueoHabitacionesPeriodosUsuarios;
    return data;
  }


  // * ------------------------------ BloqueoHabitacionesPeriodosDetalleUsuarios ---------------------------------------
  GetBloqueoHabitacionesPeriodosDetalleUsuarios(): Observable<BloqueoHabitacionesPeriodosDetalleUsuarios[]> {
    return this.bloqueoHabitacionesPeriodosDetalleUsuarios;
  }

  AddBloqueoHabitacionesPeriodosDetalleUsuarios(c: BloqueoHabitacionesPeriodosDetalleUsuarios): Promise<DocumentReference> {
    return this.bloqueoHabitacionesPeriodosDetalleUsuariosCollection.add(c);
  }

  DeleteBloqueoHabitacionesPeriodosDetalleUsuarios(id: string): Promise<void> {
    return this.bloqueoHabitacionesPeriodosDetalleUsuariosCollection.doc(id).delete();
  }

  UpdateBloqueoHabitacionesPeriodosDetalleUsuarios(c: BloqueoHabitacionesPeriodosDetalleUsuarios): Promise<void> {
    return this.bloqueoHabitacionesPeriodosDetalleUsuariosCollection.doc(c.id).update(c);
  }

  GetBloqueoHabitacionesPeriodosDetalleUsuariosByIdConfig(id: string): Observable<BloqueoHabitacionesPeriodosDetalleUsuarios[]> {
    let tempBloqueoBloqueoHabitacionesPeriodosDetalleUsuarios: Observable<BloqueoHabitacionesPeriodosDetalleUsuarios[]>;
    const collection = this.afs.collection<BloqueoHabitacionesPeriodosDetalle>('bloqueo_habitaciones_periodos_detalle_usuarios', ref => ref.where('idConfiguracion', '==', id));
    tempBloqueoBloqueoHabitacionesPeriodosDetalleUsuarios = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as BloqueoHabitacionesPeriodosDetalleUsuarios;
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempBloqueoBloqueoHabitacionesPeriodosDetalleUsuarios;
  }

  GetBloqueoHabitacionesPeriodosDetalleUsuariosByIdConfigActivos(id: string): Observable<BloqueoHabitacionesPeriodosDetalleUsuarios[]> {
    let tempBloqueoBloqueoHabitacionesPeriodosDetalleUsuarios: Observable<BloqueoHabitacionesPeriodosDetalleUsuarios[]>;
    const collection = this.afs.collection<BloqueoHabitacionesPeriodosDetalle>('bloqueo_habitaciones_periodos_detalle_usuarios', ref => ref.where('idConfiguracion', '==', id).where("estatus", "==", estatusSistema.Activo));
    tempBloqueoBloqueoHabitacionesPeriodosDetalleUsuarios = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as BloqueoHabitacionesPeriodosDetalleUsuarios;
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempBloqueoBloqueoHabitacionesPeriodosDetalleUsuarios;
  }


    // * ------------------------------ TarifaPeriodosUsuarios ---------------------------------------
    GetTarifaPeriodosUsuarios(): Observable<TarifaPeriodoUsuarios[]> {
      return this.tarifaPeriodosUsuarios;
    }
  
    AddTarifaPeriodosUsuarios(c: TarifaPeriodoUsuarios): Promise<DocumentReference> {
      return this.tarifaPeriodosUsuariosCollection.add(c);
    }
  
    DeleteTarifaPeriodosUsuarios(id: string): Promise<void> {
      return this.tarifaPeriodosUsuariosCollection.doc(id).delete();
    }
  
    UpdateTarifaPeriodosUsuarios(c: TarifaPeriodoUsuarios): Promise<void> {
      return this.tarifaPeriodosUsuariosCollection.doc(c.id).update(c);
    }
  
    GetTarifaPeriodosUsuariosActivos(): Observable<TarifaPeriodoUsuarios[]> {
      let tempTarifaPeriodosUsuarios: Observable<TarifaPeriodoUsuarios[]>;
      const collection = this.afs.collection<TarifaPeriodoUsuarios>('tarifa_periodos_usuarios', ref => ref.where('estatus', '==', estatusSistema.Activo));
      tempTarifaPeriodosUsuarios = collection.snapshotChanges().pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as TarifaPeriodoUsuarios;
            const id = a.payload.doc.id; // the document itself does not contain its Id
  
            return { id, ...data };
          });
        })
      );
      return tempTarifaPeriodosUsuarios;
    }
  
    async GetTarifaPeriodosUsuariosById(id: string): Promise<TarifaPeriodoUsuarios> {
      const col = this.afs.collection('tarifa_periodos_usuarios').doc(id);
      const data = (await col.ref.get()).data() as TarifaPeriodoUsuarios;
      return data;
    }

    GetTarifaPeriodosUsuariosBy_Date(startDate, endDate){
      return this.afs.collection('tarifa_periodos_usuarios').ref.where('fechaInicio', '>=', startDate).where('fechaInicio', '<=', endDate).orderBy('fechaInicio', 'desc').get();
    }
  
    // * ------------------------------ TarifaPeriodosUsuariosDetalle ---------------------------------------
    GetTarifaPeriodosUsuariosDetalle(): Observable<TarifaPeriodoUsuariosDetalle[]> {
      return this.tarifaPeriodosUsuariosDetalle;
    }
  
    AddTarifaPeriodosUsuariosDetalle(c: TarifaPeriodoUsuariosDetalle): Promise<DocumentReference> {
      return this.tarifaPeriodosUsuariosDetalleCollection.add(c);
    }
  
    DeleteTarifaPeriodosUsuariosDetalle(id: string): Promise<void> {
      return this.tarifaPeriodosUsuariosDetalleCollection.doc(id).delete();
    }
  
    UpdateTarifaPeriodosUsuariosDetalle(c: TarifaPeriodoUsuariosDetalle): Promise<void> {
      return this.tarifaPeriodosUsuariosDetalleCollection.doc(c.id).update(c);
    }
  
    GetTarifaPeriodosUsuariosDetalleByIdConfig(id: string): Observable<TarifaPeriodoUsuariosDetalle[]> {
      let tempBloqueoTarifaPeriodosUsuariosDetalle: Observable<TarifaPeriodoUsuariosDetalle[]>;
      const collection = this.afs.collection<TarifaPeriodoUsuariosDetalle>('tarifa_periodos_usuarios_detalle', ref => ref.where('idConfiguracion', '==', id));
      tempBloqueoTarifaPeriodosUsuariosDetalle = collection.snapshotChanges().pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as TarifaPeriodoUsuariosDetalle;
            const id = a.payload.doc.id; // the document itself does not contain its Id
  
            return { id, ...data };
          });
        })
      );
      return tempBloqueoTarifaPeriodosUsuariosDetalle;
    }
  
    GetTarifaPeriodosUsuariosDetalleByIdConfigActivos(id: string): Observable<TarifaPeriodoUsuariosDetalle[]> {
      let tempBloqueoTarifaPeriodosUsuariosDetalle: Observable<TarifaPeriodoUsuariosDetalle[]>;
      const collection = this.afs.collection<TarifaPeriodoUsuariosDetalle>('tarifa_periodos_usuarios_detalle', ref => ref.where('idConfiguracion', '==', id).where("estatus", "==", estatusSistema.Activo));
      tempBloqueoTarifaPeriodosUsuariosDetalle = collection.snapshotChanges().pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as TarifaPeriodoUsuariosDetalle;
            const id = a.payload.doc.id; // the document itself does not contain its Id
  
            return { id, ...data };
          });
        })
      );
      return tempBloqueoTarifaPeriodosUsuariosDetalle;
    }


  // * ------------------------------ TarifaPorOcupacion ---------------------------------------
  GetTarifaPorOcupacion(): Observable<TarifaPorOcupacion[]> {
    return this.tarifaPorOcupacion;
  }

  AddTarifaPorOcupacion(c: TarifaPorOcupacion): Promise<DocumentReference> {
    return this.tarifaPorOcupacionCollection.add(c);
  }

  DeleteTarifaPorOcupacion(id: string): Promise<void> {
    return this.tarifaPorOcupacionCollection.doc(id).delete();
  }

  UpdateTarifaPorOcupacion(c: TarifaPorOcupacion): Promise<void> {
    return this.tarifaPorOcupacionCollection.doc(c.id).update(c);
  }

  GetTarifaPorOcupacionActivos(): Observable<TarifaPorOcupacion[]> {
    let tempTarifaPorOcupacion: Observable<TarifaPorOcupacion[]>;
    const collection = this.afs.collection<TarifaPorOcupacion>('tarifa_por_ocupacion', ref => ref.where('estatus', '==', estatusSistema.Activo));
    tempTarifaPorOcupacion = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as TarifaPorOcupacion;
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempTarifaPorOcupacion;
  }

  async GetTarifaPorOcupacionById(id: string): Promise<TarifaPorOcupacion> {
    const col = this.afs.collection('tarifa_por_ocupacion').doc(id);
    const data = (await col.ref.get()).data() as TarifaPorOcupacion;
    return data;
  }

  GetTarifaPorOcupacionBy_Date(startDate, endDate){
    return this.afs.collection('tarifa_por_ocupacion').ref.where('fechaInicio', '>=', startDate).where('fechaInicio', '<=', endDate).orderBy('fechaInicio', 'desc').get();
  }

  // * ------------------------------ TarifaPorOcupacionDetalle ---------------------------------------
  GetTarifaPorOcupacionDetalle(): Observable<TarifaPorOcupacionDetalle[]> {
    return this.tarifaPorOcupacionDetalle;
  }

  AddTarifaPorOcupacionDetalle(c: TarifaPorOcupacionDetalle): Promise<DocumentReference> {
    return this.tarifaPorOcupacionDetalleCollection.add(c);
  }

  DeleteTarifaPorOcupacionDetalle(id: string): Promise<void> {
    return this.tarifaPorOcupacionDetalleCollection.doc(id).delete();
  }

  UpdateTarifaPorOcupacionDetalle(c: TarifaPorOcupacionDetalle): Promise<void> {
    return this.tarifaPorOcupacionDetalleCollection.doc(c.id).update(c);
  }

  GetTarifaPorOcupacionDetalleByIdConfig(id: string): Observable<TarifaPorOcupacionDetalle[]> {
    let tempTarifaPorOcupacionDetalle: Observable<TarifaPorOcupacionDetalle[]>;
    const collection = this.afs.collection<TarifaPorOcupacionDetalle>('tarifa_por_ocupacion_detalle', ref => ref.where('idConfiguracion', '==', id));
    tempTarifaPorOcupacionDetalle = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as TarifaPorOcupacionDetalle;
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempTarifaPorOcupacionDetalle;
  }
  
  GetTarifaPorOcupacionDetalleByIdConfigActivos(id: string): Observable<TarifaPorOcupacionDetalle[]> {
    let tempTarifaPorOcupacionDetalle: Observable<TarifaPorOcupacionDetalle[]>;
    const collection = this.afs.collection<TarifaPorOcupacionDetalle>('tarifa_por_ocupacion_detalle', ref => ref.where('idConfiguracion', '==', id).where("estatus", "==", estatusSistema.Activo));
    tempTarifaPorOcupacionDetalle = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as TarifaPorOcupacionDetalle;
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempTarifaPorOcupacionDetalle;
  }

  GetTarifaPorOcupacionDetallesActivos(): Observable<TarifaPorOcupacionDetalle[]> {
    let tempTarifaPorOcupacionDetalle: Observable<TarifaPorOcupacionDetalle[]>;
    const collection = this.afs.collection<TarifaPorOcupacionDetalle>('tarifa_por_ocupacion_detalle', ref => ref.where('estatus', '==', estatusSistema.Activo));
    tempTarifaPorOcupacionDetalle = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as TarifaPorOcupacionDetalle;
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempTarifaPorOcupacionDetalle;
  }

  // * ------------------------------ PAGO --------------------------------------------

  GetPago(): Observable<Pago[]> {
    return this.pago;
  }
  AddPago(t: Pago): Promise<DocumentReference> {
    return this.pagoCollection.add(t);
  }
  DeletePago(id: string): Promise<void> {
    return this.pagoCollection.doc(id).delete();
  }
  UpdatePago(p: Pago): Promise<void> {
    return this.pagoCollection.doc(p.id).update(p);
  }

  // *-------------------------CALCULARPRECIOCUARTOFECHA---------------------------------

  GetPrecioCuartoFecha(): Observable<PrecioCuartoFecha[]> {
    return this.preciocuartofecha;
  }
  AddPrecioCuartoFecha(t: PrecioCuartoFecha): Promise<DocumentReference> {
    return this.preciocuartofechaCollection.add(t);
  }
  DeletePrecioCuartoFecha(id: string): Promise<void> {
    return this.preciocuartofechaCollection.doc(id).delete();
  }
  UpdatePrecioCuartoFecha(p: PrecioCuartoFecha): Promise<void> {
    return this.preciocuartofechaCollection.doc(p.id).update(p);
  }



  // * ------------------------------ TIPO CUARTO ---------------------------------------
  AddPagoGral(t: FechasPagoGeneral): Promise<DocumentReference> {
    return this.fechaspagogeneralCollection.add(t);
  }
  GetPagoGral(): Observable<FechasPagoGeneral[]> {
    return this.fechaspagogeneral;
  }
  UpdatePagoGral(d: FechasPagoGeneral): Promise<void> {
    return this.fechaspagogeneralCollection.doc(d.id).update(d);
  }

  // * ------------------------------ TIPO CUARTO ---------------------------------------
  GetTipoCuartos(): Observable<TipoCuarto[]> {
    return this.tipoCuarto;
  }

  AddTipoCuarto(t: TipoCuarto): Promise<DocumentReference> {
    return this.tipoCuartoCollection.add(t);
  }

  DeleteTipoCuarto(id: string): Promise<void> {
    return this.tipoCuartoCollection.doc(id).delete();
  }

  UpdateTipoCuarto(t: TipoCuarto): Promise<void> {
    return this.tipoCuartoCollection.doc(t.id).update(t);
  }

  GetTipoCuartoByComplexId(idComplejo: string): Observable<TipoCuarto[]> {
    let tempData: Observable<TipoCuarto[]>;
    const collection = this.afs.collection<TipoCuarto>("tipo_cuarto", ref => ref.where("complejoId", "==", idComplejo));
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
  }

  async GetTipoCuartoById(id: string): Promise<TipoCuarto> {
    const col = this.afs.collection('tipo_cuarto').doc(id);
    const data = (await col.ref.get()).data() as TipoCuarto;
    return data;
  }

  GetDatosFacturacion(id: string): Observable<DatosFacturacion[]> {
    let temp: Observable<DatosFacturacion[]>;
    const collection = this.afs.collection<DatosFacturacion>('datos_facturacion', ref => ref.where('idReservacion', '==', id));
    temp = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as DatosFacturacion;
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { uid: id, ...data };
        });
      })
    );
    return temp;
  }

  // * ------------------------------ COMPLEJOS ---------------------------------------

  GetComplejos(): Observable<Complejo[]> {
    return this.complejo;
  }

  AddComplejo(c: Complejo): Promise<DocumentReference> {
    return this.complejoCollection.add(c);
  }

  GetComplejoByName(nom: string): Observable<Complejo[]> {
    let tempVar: Observable<Complejo[]>;
    const collection = this.afs.collection<Complejo>('complejo', ref => ref.where('nombre', '==', nom));
    tempVar = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return tempVar;
  }

  DeleteComplejo(id: string): Promise<void> {
    return this.complejoCollection.doc(id).delete();
  }

  UpdateComplejo(c: Complejo): Promise<void> {
    return this.complejoCollection.doc(c.id).update(c);
  }



  // * ------------------------------ SERVICIOS ---------------------------------------
  GetServicio(): Observable<Servicio[]> {
    return this.servicio;
  }

  AddServicio(s: Servicio): Promise<DocumentReference> {
    return this.servicioCollection.add(s);
  }

  DeleteServicio(id: string): Promise<void> {
    return this.servicioCollection.doc(id).delete();
  }

  UpdateServicio(s: Servicio): Promise<void> {
    return this.servicioCollection.doc(s.id).update(s);
  }

  async GetServicioById(id: string): Promise<Servicio> {
    const col = this.afs.collection('servicio').doc(id);
    const data = (await col.ref.get()).data() as Servicio;
    return data;
  }

  // * --------------------------- CLIENTE -------------------------------------------
  GetCliente(): Observable<Cliente[]> {
    return this.cliente;
  }
  AddCliente(cl: Cliente): Promise<DocumentReference> {
    return this.clienteCollection.add(cl);
  }
  DeleteCliente(id: string): Promise<void> {
    return this.clienteCollection.doc(id).delete();
  }

  UpdateCliente(cl: Cliente): Promise<void> {
    return this.clienteCollection.doc(cl.id).update(cl);
  }

  // ? ------------------- FIREBASE STORAGE UPLOAD/GET IMAGE -------------------------------
  async CreateUploadTask(file: any, fullPathFile: string) {
    const filePathn = '/imagenes/recamaras/' + fullPathFile; // * firebase storage path here (folder{s})
    const ref = this.storage.ref(filePathn);
    const task = await ref.put(file);
    const surl = await task.ref.getDownloadURL();
    return surl;
    // const file = event.target.files[0];
    // const filePath = '/categories_img/' + fullPathFile; // * firebase storage path here (folder{s})
    // const ref = this.storage.ref(filePath);
    // const task = ref.put(file); // * also exist putString() and upload()
    // this.progress = task.percentageChanges();
  }
  async CreateUploadTask_Complejos(file: any, fullPathFile: string) {
    const filePathn = '/imagenes/complejos/' + fullPathFile; // * firebase storage path here (folder{s})
    const ref = this.storage.ref(filePathn);
    const task = await ref.put(file);
    const surl = await task.ref.getDownloadURL();
    return surl;
    // const file = event.target.files[0];
    // const filePath = '/categories_img/' + fullPathFile; // * firebase storage path here (folder{s})
    // const ref = this.storage.ref(filePath);
    // const task = ref.put(file); // * also exist putString() and upload()
    // this.progress = task.percentageChanges();
  }

  async DeleteFromStorage(url: string) {
    console.log('REF URL: ', url);
    try {
      const ref = this.storage.storage.refFromURL(url);
      await ref.delete();
    } catch (error) {
      console.log(error);
    }
    // console.log(ref);

    // const task = await ref.put(file);
    // const surl = await task.ref.getDownloadURL();
  }

  GetImage(fullPathFile: string): Observable<any> {
    // //console.log('name_' + fullPathFile);
    // //console.log(this.storage.ref('/appforms-img/').getDownloadURL());

    const fileRef = this.storage.ref('/categories_img/' + fullPathFile);
    // //console.log(fileRef.getDownloadURL());
    // //console.log(fileRef);

    return fileRef.getDownloadURL(); // return observable

    // ? then use this
    //  const img = this.fbs.GetImage('');
    //  img.subscribe(data => this.image = data);
  }


  // ?
  // async GetPedido_TuTiendita(pedido: PedidoRestaurant): Promise<Pedido> {
  //   const collection = this.afs.collection<Pedido>('pedidos').doc(pedido.pedidoId);
  //   const data = (await collection.ref.get()).data() as Pedido;
  //   return data;
  // }

}
