import { Injectable, OnInit } from '@angular/core';
import { Observable, Observer, fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';

export enum Proyecto {
    test = 0,
    grandPrix = 1,
    azulArena = 2
}

@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    // ? CAMBIAR AQUI PARA SELECCIONAR UNO U OTRO PROYECTO
    // * -- environment.ts
    // * -- base URL en index.html
    // * -- title en index.html

    protocol = "https"; // ? FOR REQUEST IN ERP SERVICE
    domain = "";
    selectProyect: Proyecto = Proyecto.azulArena;
    sis_id = -1;
    appName = "";
    appUrl = "";
    logo = "";
    logoRegistro = "";
    logoWhite = "";
    token = ""; // token para conectarse al ERP
    emailUrl = ""; // token para conectarse al ERP
    sendEmailHotel = "";
    sendEmailHotel2 = "";
    emailEstatus = "";
    hotelName = "";
    IVA = 0;
    /**
     * impuesto ISH
     */
    hospedaje = 0;
    tablaSuite = false;
    margenHeight = 0;
    margenHeightEstadoDetalles = 0;
    fitLogo = [];
    fitLogoRegistro = [];
    fitRegistroY = 0;
    fitRegistroX = 0;

    environment = {};
    appColor: string;
    hexColor: string;
    loginTimeOut = 0;


    createOnline$() {
        return merge<boolean>(
            fromEvent(window, 'offline').pipe(map(() => false)),
            fromEvent(window, 'online').pipe(map(() => true)),
            new Observable((sub: Observer<boolean>) => {
                sub.next(navigator.onLine);
                sub.complete();
            }));
    }



    /**
     * called in app.component.ts
     */
    async LoadConfig() {

        const response = await fetch('assets/protocol.txt');
        let protocol = await response.text();
        console.log(protocol)

        if (this.selectProyect == Proyecto.test) {
            this.appName = "HOTEL TEST <-";
            this.hotelName = "Hotel Test";
            this.appUrl = "localhost:8100/";
            this.logo = "assets/img/logo_weenjoy.png";
            this.logoRegistro = "assets/img/logo_weenjoy.png";
            this.logoWhite = "assets/img/logo_weenjoy.png";
            this.token = "123456";
            this.sendEmailHotel = "consultoriaintegrapro@gmail.com";
            this.sendEmailHotel2 = "";
            this.domain = "pruebaserp";
            this.emailUrl = "https://correo.konsultor.com.mx/prueba/sendEmail.php";
            // this.emailUrl = protocol + "://azularena.mx/reservaciones/php_service/sendEmail.php";
            this.IVA = 0.16;
            this.hospedaje = 0.03;
            // this.hospedaje = 0.035;
            this.tablaSuite = false;
            this.margenHeight = 170;
            this.margenHeightEstadoDetalles = 120;
            this.fitLogo = [200, 200];
            this.fitLogoRegistro = [300, 300];
            this.appColor = 'azularena';
            this.fitRegistroY = 170;
            this.fitRegistroX = 30;
            this.hexColor = '#0279a8';
            // this.loginTimeOut = 5000;
            this.loginTimeOut = 9999999999999;
            this.emailEstatus = "consultoriaintegrapro@gmail.com";
            this.sis_id = 1;
        } else if (this.selectProyect == Proyecto.azulArena) {
            this.appName = "HOTEL AZUL ARENA";
            this.hotelName = "Hotel Azul Arena";
            this.appUrl = "http://azularenah.konsultor.com.mx/";
            this.logo = "assets/img/logo_azul_arena_colorida.png";
            this.logoRegistro = "assets/img/logo_azul_arena_colorida_detalles.jpg";
            this.logoWhite = "assets/img/logo_azul_arena.png";
            this.token = "4zuLaR3na$";
            this.sendEmailHotel = "reservaciones@azularena.mx";
            this.sendEmailHotel2 = "";
            this.domain = "erp";
            this.emailUrl = "https://correo.konsultor.com.mx/azul_arena/sendEmail.php";
            this.IVA = 0.16;
            this.hospedaje = 0.03;
            this.tablaSuite = false;
            this.margenHeight = 210;
            this.margenHeightEstadoDetalles = 140;
            this.fitLogo = [100, 100];
            this.fitLogoRegistro = [300, 300];
            this.appColor = 'azularena';
            this.hexColor = '#0279a8';
            this.fitRegistroY = 150;
            this.fitRegistroX = 30;
            this.loginTimeOut = 600000;
            this.sis_id = 1;
            this.emailEstatus = "reservaciones@azularena.mx";
        }
        else if (this.selectProyect == Proyecto.grandPrix) {
            this.appName = "HOTEL WEEnjoy";
            this.hotelName = "Hotel WEEnjoy";
            this.appUrl = "http://grandprixh.konsultor.com.mx/"; // https://grandprixh.konsultor.mx/#/
            this.logo = "assets/img/logo_weenjoy.png";
            this.logoRegistro = "assets/img/logo_weenjoy.png";
            this.logoWhite = "assets/img/logo_weenjoy.png";
            this.token = "Gr4ndPr1x$";
            this.sendEmailHotel = "reservaciones@hotelgrandprix.com.mx";
            this.sendEmailHotel2 = "recepcion@hotelgrandprix.com.mx";
            this.domain = "erp";
            // TODO: cambiar correo para grand prix con su propia configuracion en su servidor grandprix.konsulto.mx
            this.emailUrl = "https://correo.konsultor.com.mx/grandprix/sendEmail.php";
            this.IVA = 0.16;
            this.hospedaje = 0.035;
            this.tablaSuite = true;
            this.margenHeight = 170;
            this.margenHeightEstadoDetalles = 120;
            this.fitLogo = [200, 200];
            this.fitLogoRegistro = [300, 300];
            this.appColor = 'azularena';
            this.hexColor = '#0279a8';
            this.fitRegistroY = 170;
            this.fitRegistroX = 30;
            this.loginTimeOut = 600000;
            this.sis_id = 1;
            this.emailEstatus = "gpreservacionesGPX@gmail.com";
        }


    }



}


