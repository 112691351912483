import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestore, AngularFirestoreModule, PERSISTENCE_SETTINGS } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { environment } from '../environments/environment';

import { CryptoClass } from 'src/services/user-data.service';

import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from "@fortawesome/free-regular-svg-icons";
// import { NgxQRCodeModule } from "ngx-qrcode2";
// import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';

import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { SETTINGS as SETTINGS_FIRESTORE } from '@angular/fire/firestore';
registerLocaleData(es);

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireStorageModule,
    //AngularFirestoreModule.enablePersistence({ synchronizeTabs: true }),
    AngularFireAuthGuardModule,
    AngularFireAuthModule,
    // NgxQRCodeModule,
    FontAwesomeModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    AngularFirestore,
    CryptoClass,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    // { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: "es-MX",  },
    {
      provide: SETTINGS_FIRESTORE,
      useValue: { experimentalForceLongPolling: true },
    },

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIconPacks(fas, far);
  }
}
