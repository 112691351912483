import { Injectable, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import * as CryptoJS from 'crypto-js';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FirebaseDataService, Formulariobm, Reservacion, Sys_log, UserAdmin, User } from './firebase-data.service';
import { Permisos } from 'src/shared/permissions.interface';
import { AppComponent } from 'src/app/app.component';
import { async } from '@angular/core/testing';
import { ConfigService } from './config.service';
import moment from 'moment';
import axios from 'axios';
import { auth } from 'firebase';


// ---------------------------------- ENCRYPT/DECRYPT -----------------------------------
@Injectable()
export class CryptoClass {
  private secretKey = 'Gur4Nd0Pr!x_Ho73ru';
  constructor() { }

  Encrypt(dataToEncrypt: any) {
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(dataToEncrypt), this.secretKey).toString();
    // //console.log(encryptedData);
    return encryptedData;
  }
  /**
   *
   * @param encryptedData data must be string
   */
  Decrypt(encryptedData: string) {
    const bytes = CryptoJS.AES.decrypt(encryptedData, this.secretKey);
    const obj = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    // //console.log(obj);
    return obj;
  }
}




@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  private users: Observable<User[]>;
  public formulariobm: Formulariobm;
  public myReservation: Reservacion;
  private userCollection: AngularFirestoreCollection<User>;

  public appInstance: AppComponent;

  // public baseUrl: string = "localhost:8100/";
  public baseUrl: string = "";

  constructor(private afs: AngularFirestore, private config: ConfigService, private firebaseService: FirebaseDataService) {
    this.baseUrl = config.appUrl;
    this.userCollection = this.afs.collection<User>('users');
    this.users = this.userCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
  }

  currentUser: UserAdmin;
  guestUser: User;
  isLogged = false;
  permissions: Permisos;
  modules = [];
  isAdmin = false;

  complejoSeleccionado = '';
  cuartoSeleccionado = 'sin seleccionar';
  idCuarto = '';
  idReservacion = '';

  async SetUserAdminData(user: UserAdmin) {
    this.isLogged = true;
    this.isAdmin = true;
    this.currentUser = user;

    this.permissions = this.EmptyPermissions();
    this.SetPermission(this.currentUser.permisos);

    console.log('logged as: ', this.currentUser);
    console.log('Permissions: ', this.permissions);

    const response = await axios.get("https://ipinfo.io/json?token=be017536ee50f7");

    const timeStamp = moment().format('YYYY-MM-DD HH:mm:ss') + ":" + moment().millisecond();
    let sys_log: Sys_log = {
      nombre: "Login",
      fecha: timeStamp,
      usu_id: this.currentUser.uid,
      datos_nuevos: ['nombre: ' + this.currentUser.nombre, 'username: ' + this.currentUser.usuario, 'id: ' + this.currentUser.id, response.data],
      datos_viejos: [],
      movimiento: "Inicio de session",
      idReservacion: ''
    };
    await this.firebaseService.AddSys_log(sys_log);
  }

  async SetUserGuestData(user: User) {
    this.isLogged = true;
    this.isAdmin = false;

    this.guestUser = user;

    this.permissions = this.EmptyPermissions();
    // this.SetPermission(this.guestUser.permisos);

    // console.log('logged as: ', this.guestUser);
    // console.log('Permissions: ', this.permissions);

    const response = await axios.get("https://ipinfo.io/json?token=be017536ee50f7");

    const timeStamp = moment().format('YYYY-MM-DD HH:mm:ss') + ":" + moment().millisecond();
    let sys_log: Sys_log = {
      nombre: "Login",
      fecha: timeStamp,
      usu_id: this.guestUser.uid,
      datos_nuevos: ['nombre: ' + this.guestUser.name, 'Email: ' + this.guestUser.email, 'id: ' + this.guestUser.id, response.data],
      datos_viejos: [],
      movimiento: "Inicio de session",
      idReservacion: ''
    };
    await this.firebaseService.AddSys_log(sys_log);
  }



  /**
   * Set permissions and modules that does not exist in firebase data
   */
  SetPermission(userPermissions) {
    this.modules = [];
    for (const mod in this.permissions) {
      this.modules.push(mod);
    }

    let tmpPermisos = userPermissions;
    for (let i = 0; i < this.modules.length; i++) {
      const mod = this.modules[i];
      if (tmpPermisos[mod] !== undefined) {

        for (let p = 0; p < this.permissions[mod].length; p++) {

          if (tmpPermisos[mod][p] !== undefined) {
            this.permissions[mod][p].value = tmpPermisos[mod][p].value;
          } else {
            // console.log(`no existe en: ${mod} pos: ${p}`);
          }
        }
      }
    }
  }

  async WaitUserData() {
    return new Promise((resolve, reject) => {
      var tries = 5;
      var count = 0;
      var interval = setInterval(() => {
        if (this.isLogged) {
          clearInterval(interval);
          resolve(true);
        }
        count++;
        if (count >= tries) {
          clearInterval(interval);
          resolve(false);
        }
      }, 300);
    });

  }

  SignOut() {
    this.isLogged = false;
    this.isAdmin = false;
    this.currentUser = null;
    this.guestUser = null;
    this.permissions = undefined;
  }

  ShowInfo() {
    console.log('User Info:', this.currentUser);
    console.log('Is Logged:', this.isLogged);
    console.log('complejoId:', this.complejoSeleccionado);
    console.log('cuartoId:', this.idCuarto);
  }

  /**
   * GET APP USERS ONLY, NOT ADMIN
   *  
   * */
  GetUsers(): Observable<User[]> {
    return this.users;
  }



  /**
   * Inicializar vacío
   */
  EmptyPermissions(): Permisos {
    return {
      ["usuarios_admin"]: [
        {
          value: false,
          description: "Crear"
        },
        {
          value: false,
          description: "Consultar"
        },
        {
          value: false,
          description: "Editar"
        },
        {
          value: false,
          description: "Borrar"
        },
        {
          value: false,
          description: "Editar Permisos"
        },
      ],
      ["reservaciones"]: [
        {
          value: false,
          description: "Crear"
        },
        {
          value: false,
          description: "Consultar"
        },
        {
          value: false,
          description: "Editar"
        },
        {
          value: false,
          description: "Borrar"
        },
        {
          value: false,
          description: "Asignar usuario"
        },
        {
          value: false,
          description: "Realizar check-in"
        },
        {
          value: false,
          description: "Realizar check-out"
        },
        {
          value: false,
          description: "Imprimir reservación"
        },
        {
          // 8
          value: false,
          description: "Cambiar segmento"
        },
        {
          // 9
          value: false,
          description: "Cambiar tarifa"
        },
        {
          // 10
          value: false,
          description: "Agregar persona extra"
        },
        {
          // 11
          value: false,
          description: "Mostrar información de tarjeta"
        },
        {
          // 12
          value: false,
          description: "Agrega extras"
        },
        {
          // 13
          value: false,
          description: "Dar abonos"
        },
        {
          // 14
          value: false,
          description: "Mostrar estado de cuenta"
        },
        {
          // 15
          value: false,
          description: "Cerrar turno"
        },
        {
          // 16
          value: false,
          description: "Crear cuenta sin habitacion"
        },
        {
          value: false,
          description: "Hoja Registro"
        },
        {
          value: false,
          description: "Regresar estatus anterior"
        },
        {
          value: false,
          description: "Ventas"
        },
        {
            value: false,
            description: "Separar Habitación"
        }
      ],
      ["servicios"]: [
        {
          value: false,
          description: "Crear"
        },
        {
          value: false,
          description: "Consultar"
        },
        {
          value: false,
          description: "Editar"
        },
        {
          value: false,
          description: "Borrar"
        }
      ],
      ["complejos"]: [
        {
          value: false,
          description: "Crear"
        },
        {
          value: false,
          description: "Consultar"
        },
        {
          value: false,
          description: "Editar"
        },
        {
          value: false,
          description: "Borrar"
        }
      ],
      ["tipo_cuartos"]: [
        {
          value: false,
          description: "Crear"
        },
        {
          value: false,
          description: "Consultar"
        },
        {
          value: false,
          description: "Editar"
        },
        {
          value: false,
          description: "Borrar"
        },
        {
          value: false,
          description: "Reactivar"
        },
      ],
      ["cuartos"]: [
        {
          value: false,
          description: "Crear"
        },
        {
          value: false,
          description: "Consultar"
        },
        {
          value: false,
          description: "Editar"
        },
        {
          value: false,
          description: "Borrar"
        },
        {
          value: false,
          description: "Crear numeros de cuarto"
        },
        {
          value: false,
          description: "Consultar numeros de cuarto"
        },
        {
          value: false,
          description: "Editar numeros de cuarto"
        },
        {
          value: false,
          description: "Borrar numeros de cuarto"
        },
        {
          value: false,
          description: "Cambiar Disponible/Bloqueado"
        },
        {
          value: false,
          description: "Crear Bloqueo de Habitaciones por Periodos"
        },
        {
          value: false,
          description: "Consultar Bloqueo de Habitaciones por Periodos"
        },
        {
          value: false,
          description: "Editar Bloqueo de Habitaciones por Periodos"
        },
        {
          value: false, //12
          description: "Borrar Bloqueo de Habitaciones por Periodos"
        },
        {
          value: false,
          description: "modificar la habitacion SIN HABITACION"
        },
        {
          value: false,
          description: "Crear Bloqueo de Habitaciones por Periodos para Usuarios"
        },
        {
            value: false,
            description: "Consultar Bloqueo de Habitaciones por Periodos para Usuarios"
        },
        {
            value: false,
            description: "Editar Bloqueo de Habitaciones por Periodos para Usuarios"
        },
        {
            value: false, //17
            description: "Borrar Bloqueo de Habitaciones por Periodos para Usuarios"
        },
        {
          value: false, //18
          description: "Reactivar"
        },
        {
          value: false, //19
          description: "Reactivar numero de cuarto"
        },
        {
          value: false, //20
          description: "Visualizar numeros de cuarto"
        },
        {
          value: false,
          description: "Reactivar Bloqueo de Habitaciones por Periodos"
        },
        {
          value: false, //22
          description: "Reactivar Bloqueo de Habitaciones por Periodos para Usuarios"
      },
      ],
      ["tipo_servicios"]: [
        {
          value: false,
          description: "Crear"
        },
        {
          value: false,
          description: "Consultar"
        },
        {
          value: false,
          description: "Editar"
        },
        {
          value: false,
          description: "Borrar"
        },
        {
          value: false,
          description: "Consultar sub-servicio"
        },
        {
          value: false,
          description: "Editar sub-servicio"
        },
        {
          value: false,
          description: "Borrar sub-servicio"
        },
      ],
      ["conductores"]: [
        {
          value: false,
          description: "Crear"
        },
        {
          value: false,
          description: "Consultar"
        },
        {
          value: false,
          description: "Editar"
        },
        {
          value: false,
          description: "Borrar"
        },
      ],
      ["formularios"]: [
        {
          value: false,
          description: "Crear"
        },
        {
          value: false,
          description: "Consultar"
        },
        {
          value: false,
          description: "Editar"
        },
        {
          value: false,
          description: "Borrar"
        },
        {
          value: false,
          description: "Contestar formulario"
        },
      ],
      ["formularios_dinamicos"]: [
        {
          value: false,
          description: "Crear"
        },
        {
          value: false,
          description: "Consultar"
        },
        {
          value: false,
          description: "Editar"
        },
        {
          value: false,
          description: "Borrar"
        },
        {
          value: false,
          description: "Contestar formulario"
        },
      ],
      ["configuraciones"]: [
        {
          value: false,
          description: "Crear"
        },
        {
          value: false,
          description: "Consultar"
        },
        {
          value: false,
          description: "Editar"
        },
        {
          value: false,
          description: "Borrar"
        },
        {
          value: false, // 4
          description: "Modificar formulario check in"
        },
        {
          value: false, // 5
          description: "Modificar formulario check out"
        },
        {
          value: false, // 6
          description: "Modificar nombre de usuario"
        },
        {
          value: false, // 7
          description: "Modificar URL de app"
        },
        {
          value: false, // 8
          description: "Activar check-in"
        },
        {
          value: false, // 9
          description: "Activar check-out"
        },
        {
          value: false, // 10
          description: "Hora de check in"
        },
        {
          value: false, // 11
          description: "Hora de check out"
        },
        {
          value: false, // 12
          description: "Modificar colores"
        },
        {
          value: false, // 13
          description: "Activar reservacion completa"
        },
        {
          value: false, // 14
          description: "Modificar imagen de configuracion"
        },
      ],
      ["solicitud_transportes"]: [
        {
          value: false,
          description: "Crear"
        },
        {
          value: false,
          description: "Consultar"
        },
        {
          value: false,
          description: "Editar"
        },
        {
          value: false,
          description: "Borrar"
        },
        {
          value: false,
          description: "Filtrar por estatus"
        },
        {
          value: false,
          description: "Completar solicitud"
        },
        {
          value: false,
          description: "Cancelar solicitud"
        },
      ],
      ["reportes"]: [
        {
          value: false,
          description: "Crear"
        },
        {
          value: false,
          description: "Consultar"
        },
        {
          value: false,
          description: "Editar"
        },
        {
          value: false,
          description: "Borrar"
        },
        {
          value: false,
          description: "Reporte Habitaciones"
        },
        {
          value: false,
          description: "Reporte Reservaciones"
        },
        {
          value: false,
          description: "Reporte Turno"
        },
        /*{
          value: false,
          description: "Reporte Segmento"
        },*/
        {
          value: false,
          description: "Reporte Segmentos"//GrandPrix
        },
        {
          value: false,
          description: "Reporte Pronostico Ocupacion"
        },
        {
          value: false,
          description: "Reporte Habitaciones por Huesped"
        },
        {
          value: false,
          description: "Reporte Disponibilidad"
        },
        {//11
          value: false,
          description: "Reporte Segmentos Empleados"
        },
      ],
      ["tarifas_periodo_usuarios"]: [
        {
          value: false,
          description: "Crear"
        },
        {
          value: false,
          description: "Consultar"
        },
        {
          value: false,
          description: "Editar"
        },
        {
          value: false,
          description: "Borrar"
        },
        {
          value: false,
          description: "Reactivar"
        },
      ],
      ["tarifas_por_ocupacion"]: [
        {
          value: false,
          description: "Crear"
        },
        {
          value: false,
          description: "Consultar"
        },
        {
          value: false,
          description: "Editar"
        },
        {
          value: false,
          description: "Borrar"
        },
        {
          value: false,
          description: "Reactivar"
        },
      ],
      ["clientes"]: [
        {
          value: false,
          description: "Crear"
        },
        {
          value: false,
          description: "Consultar"
        },
        {
          value: false,
          description: "Editar"
        },
        {
          value: false,
          description: "Borrar"
        }
      ],
      ["politicas"]: [
        {
          value: false,
          description: "Crear"
        },
        {
          value: false,
          description: "Consultar"
        },
        {
          value: false,
          description: "Editar"
        },
        {
          value: false,
          description: "Borrar"
        },
        {
          value: false,
          description: "Crear Tipo"
        },
        {
          value: false,
          description: "Consultar Tipo"
        },
        {
          value: false,
          description: "Editar Tipo"
        },
        {
          value: false,
          description: "Borrar Tipo"
        },
        {
          value: false,
          description: "Reordenar politicas"
        },
      ],
      ["calcular_pago"]: [
        {   // 0
          value: false,
          description: "Crear"
        },
        {   // 1
          value: false,
          description: "Consultar"
        },
        {   // 2
          value: false,
          description: "Editar"
        },
        {    // 3
          value: false,
          description: "Borrar"
        },
      ],
      ["fechas_pago"]: [
        {   // 0
          value: false,
          description: "Crear"
        },
        {   // 1
          value: false,
          description: "Consultar"
        },
        {   // 2
          value: false,
          description: "Editar"
        },
        {    // 3
          value: false,
          description: "Borrar"
        },
      ],
    };
  }




}

@Injectable()
export class PasswordValidation {
  // 
  static MatchPassword(AC: AbstractControl) {
    const password = AC.get('password').value; // to get value in input tag
    const confirmPassword = AC.get('confirmPassword').value; // to get value in input tag
    if (password !== confirmPassword) {
      // //console.log('false');
      AC.get('confirmPassword').setErrors({ MatchPassword: true });
    } else {
      // //console.log('true');
      return null;
    }
  }

}
