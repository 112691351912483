// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


// ? GRAND PRIX 
// export const environment = {
//   production: false,
//   firebaseConfig: {
//     apiKey: "AIzaSyBqWPERyifZmPBxHVErtRmNya4xvKNNwEE",
//     authDomain: "hotel-grand-prix.firebaseapp.com",
//     projectId: "hotel-grand-prix",
//     storageBucket: "hotel-grand-prix.appspot.com",
//     messagingSenderId: "752189100633",
//     appId: "1:752189100633:web:f62004b7e1c6714d5f9748",
//     measurementId: "G-PJGCV2L14N"
//   }
// };



// ? APP AZUL ARENA
export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyB7TFQBXWfTDjqSYqh6cnQtubYgaRsEHT0",
    authDomain: "azularena-3bd9c.firebaseapp.com",
    projectId: "azularena-3bd9c",
    storageBucket: "azularena-3bd9c.appspot.com",
    messagingSenderId: "1052448424812",
    appId: "1:1052448424812:web:a187079819945037aca949",
    measurementId: "G-2W12L9FFNK"
  }
};



// ? APP HOTEL TESTING
// export const environment = {
//   production: false,
//   firebaseConfig: {
//     apiKey: 'AIzaSyALuXUmPdA3X7Ll0MCXn2sczqfyLbOQhWc',
//     authDomain: 'hoteles-6d5d6.firebaseapp.com',
//     databaseURL: 'https://hoteles-6d5d6.firebaseio.com',
//     projectId: 'hoteles-6d5d6',
//     storageBucket: 'hoteles-6d5d6.appspot.com',
//     messagingSenderId: '974015841732',
//     appId: '1:974015841732:web:a25eb04cfdc66f38879091',
//     measurementId: 'G-B97PFZTFCT'
//   }
// };

export const secretKey = 'KEY'; // also in .prod.ts


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
