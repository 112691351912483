import { NgModule } from '@angular/core';
import { AngularFireAuthGuard, isNotAnonymous } from '@angular/fire/auth-guard';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

// ? AUTH GUARD REDIRECT
export const redirectAnonymousTo = (redirect: any[]) =>
  pipe(isNotAnonymous, map(loggedIn => loggedIn || redirect)
  );
const redirectUnauthorizedToLogin = () => redirectAnonymousTo(['login']);

const routes: Routes = [
  {
    path: '',
    redirectTo: 'reservaciones',
    pathMatch: 'full'
  },
  {
    path: 'alta-servicios',
    loadChildren: () => import('./alta-servicios/alta-servicios.module').then(m => m.AltaServiciosPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin } // ? USING REDIRECT
  },
  {
    path: 'alta-clientes',
    loadChildren: () => import('./alta-clientes/alta-clientes.module').then(m => m.AltaClientesPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin}
  },
  {
    path: 'alta-pagos',
    loadChildren: () => import('./alta-pagos/alta-pagos.module').then(m => m.AltaPagosPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin}
  },
  {
    path: 'calcular-pago',
    loadChildren: () => import('./calcular-pago/calcular-pago.module').then(m => m.CalcularPagoPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin}
  },
  {
    path: 'alta-cuartos',
    loadChildren: () => import('./alta-cuartos/alta-cuartos.module').then(m => m.AltaCuartosPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin } // ? USING REDIRECT
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin } // ? USING REDIRECT
  },
  {
    path: 'alta-complejos',
    loadChildren: () => import('./alta-complejos/alta-complejos.module').then(m => m.AltaComplejosPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin } // ? USING REDIRECT
  },
  {
    path: 'alta-tipo-cuartos',
    loadChildren: () => import('./alta-tipo-cuartos/alta-tipo-cuartos.module').then(m => m.AltaTipoCuartosPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin } // ? USING REDIRECT
  },
  {
    path: 'reservaciones',
    loadChildren: () => import('./reservaciones/reservaciones.module').then(m => m.ReservacionesPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin } // ? USING REDIRECT
  },
  {
    path: 'check-in1',
    loadChildren: () => import('./check-in1/check-in1.module').then(m => m.CheckIn1PageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin } // ? USING REDIRECT
  },
  {
    path: 'alta-formularios',
    loadChildren: () => import('./alta-formularios/alta-formularios.module').then(m => m.AltaFormulariosPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin } // ? USING REDIRECT
  },
  {
    path: 'formulariobm/:id',
    loadChildren: () => import('./formulariobm/formulariobm.module').then(m => m.FormulariobmPageModule),
    // canActivate: [AngularFireAuthGuard],
    // data: { authGuardPipe: redirectUnauthorizedToLogin } // ? USING REDIRECT
  },
  {
    path: 'configuracion',
    loadChildren: () => import('./configuracion/configuracion.module').then(m => m.ConfiguracionPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin } // ? USING REDIRECT
  },
  {
    path: 'generar-qr',
    loadChildren: () => import('./generar-qr/generar-qr.module').then(m => m.GenerarQrPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin } // ? USING REDIRECT
  },
  {
    path: 'formulario-dinamico',
    loadChildren: () => import('./formulario-dinamico/formulario-dinamico.module').then(m => m.FormularioDinamicoPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin } // ? USING REDIRECT
  },
  {
    path: 'contestar-form-detallado/:id',
    loadChildren: () => import('./contestar-form-detallado/contestar-form-detallado.module').then(m => m.ContestarFormDetalladoPageModule)
  },
  {
    path: 'reporte-reservaciones',
    loadChildren: () => import('./reportes/reporte-reservaciones/reporte-reservaciones.module').then(m => m.ReporteReservacionesPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin } // ? USING REDIRECT
  },
  {
    path: 'solicitud-transporte',
    loadChildren: () => import('./solicitud-transporte/solicitud-transporte.module').then(m => m.SolicitudTransportePageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin } // ? USING REDIRECT
  },
  {
    path: 'maps',
    loadChildren: () => import('./maps/maps.module').then(m => m.MapsPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin } // ? USING REDIRECT
  },
  {
    path: 'altas-tipos',
    loadChildren: () => import('./altas-tipos/altas-tipos.module').then(m => m.AltasTiposPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin } // ? USING REDIRECT
  },
  {
    path: 'conductores',
    loadChildren: () => import('./conductores/conductores.module').then(m => m.ConductoresPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin } // ? USING REDIRECT
  },
  {
    path: 'permisos',
    loadChildren: () => import('./permisos/permisos.module').then(m => m.PermisosPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin } // ? USING REDIRECT
  },
  {
    path: 'usuarios-admin',
    loadChildren: () => import('./usuarios-admin/usuarios-admin.module').then(m => m.UsuariosAdminPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin } // ? USING REDIRECT
  },
  {
    path: 'change-password',
    loadChildren: () => import('./change-password/change-password.module').then(m => m.ChangePasswordPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin } // ? USING REDIRECT
  },
  {
    path: 'check-out',
    loadChildren: () => import('./check-out/check-out.module').then(m => m.CheckOutPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin } // ? USING REDIRECT
  },
  {
    path: 'politicas',
    loadChildren: () => import('./politicas/politicas.module').then( m => m.PoliticasPageModule)
  },
  {
    path: 'reportes-menu',
    loadChildren: () => import('./reportes/reportes-menu/reportes-menu.module').then( m => m.ReportesMenuPageModule)
  },
  {
    path: 'reporte-habitaciones',
    loadChildren: () => import('./reportes/reporte-habitaciones/reporte-habitaciones.module').then( m => m.ReporteHabitacionesPageModule)
  },
  {
    path: 'reporte-turno',
    loadChildren: () => import('./reportes/reporte-turno/reporte-turno.module').then( m => m.ReporteTurnoPageModule)
  },
  {
    path: 'reporte-segmento',
    loadChildren: () => import('./reportes/reporte-segmento/reporte-segmento.module').then( m => m.ReporteSegmentoPageModule)
  },
  {
    path: 'reporte-pronostico-ocupacion',
    loadChildren: () => import('./reportes/reporte-pronostico-ocupacion/reporte-pronostico-ocupacion.module').then( m => m.ReportePronosticoOcupacionPageModule)
  },
  {
    path: 'reporte-habitaciones-huesped',
    loadChildren: () => import('./reportes/reporte-habitaciones-huesped/reporte-habitaciones-huesped.module').then( m => m.ReporteHabitacionesHuespedPageModule)
  },
  {
    path: 'reporte-segmentos',
    loadChildren: () => import('./reportes/reporte-segmentos/reporte-segmentos.module').then( m => m.ReporteSegmentosPageModule)
  },
  {
    path: 'reporte-disponibilidad',
    loadChildren: () => import('./reportes/reporte-disponibilidad/reporte-disponibilidad.module').then( m => m.ReporteDisponibilidadPageModule)
  },
  {
    path: 'syslogs',
    loadChildren: () => import('./syslogs/syslogs.module').then( m => m.SyslogsPageModule)
  },
  {
    path: 'registrar-cliente',
    loadChildren: () => import('./registrar-cliente/registrar-cliente.module').then( m => m.RegistrarClientePageModule)
  },
  {
    path: 'tarifas-periodo-usuarios',
    loadChildren: () => import('./tarifas-periodo-usuarios/tarifas-periodo-usuarios.module').then( m => m.TarifasPeriodoUsuariosPageModule)
  },  {
    path: 'tarifas-periodo-usuarios-agregar',
    loadChildren: () => import('./tarifas-periodo-usuarios-agregar/tarifas-periodo-usuarios-agregar.module').then( m => m.TarifasPeriodoUsuariosAgregarPageModule)
  },
  {
    path: 'tarifas-periodo-usuarios-consultar',
    loadChildren: () => import('./tarifas-periodo-usuarios-consultar/tarifas-periodo-usuarios-consultar.module').then( m => m.TarifasPeriodoUsuariosConsultarPageModule)
  },
  {
    path: 'tarifas-periodo-usuarios-editar',
    loadChildren: () => import('./tarifas-periodo-usuarios-editar/tarifas-periodo-usuarios-editar.module').then( m => m.TarifasPeriodoUsuariosEditarPageModule)
  },
  {
    path: 'reporte-segmentos-empleados',
    loadChildren: () => import('./reportes/reporte-segmentos-empleados/reporte-segmentos-empleados.module').then( m => m.ReporteSegmentosEmpleadosPageModule)
  },
  {
    path: 'tarifas-por-ocupacion',
    loadChildren: () => import('./tarifas-por-ocupacion/tarifas-por-ocupacion.module').then( m => m.TarifasPorOcupacionPageModule)
  },
  {
    path: 'tarifas-por-ocupacion-agregar',
    loadChildren: () => import('./tarifas-por-ocupacion-agregar/tarifas-por-ocupacion-agregar.module').then( m => m.TarifasPorOcupacionAgregarPageModule)
  },
  {
    path: 'tarifas-por-ocupacion-consultar',
    loadChildren: () => import('./tarifas-por-ocupacion-consultar/tarifas-por-ocupacion-consultar.module').then( m => m.TarifasPorOcupacionConsultarPageModule)
  },
  {
    path: 'tarifas-por-ocupacion-editar',
    loadChildren: () => import('./tarifas-por-ocupacion-editar/tarifas-por-ocupacion-editar.module').then( m => m.TarifasPorOcupacionEditarPageModule)
  },















];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
