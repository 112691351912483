import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthService } from 'src/services/auth.service';
import { estatusSistema, FirebaseDataService, Turno, TurnoActivo, User, UserAdmin } from 'src/services/firebase-data.service';
import { Observable } from 'rxjs';
import { UserDataService } from 'src/services/user-data.service';
import { ConfigService } from 'src/services/config.service';
import moment from "moment";
import { AlertsService } from 'src/services/alerts.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;

  public notLogedPages = [
    {
      title: 'Iniciar Sesión',
      url: '/login',
      icon: 'people-outline',
      allow: true
    },
  ]


  // allow used for user permissions to show or not in config
  // disabled, to disable click to that page (as readonly)
  public appPages = [
    { // 0
      title: 'Reservaciones',
      url: '/reservaciones',
      icon: 'key-outline',
      allow: false,
      disabled: false,
    },
    {// 1
      title: 'Solicitud de Transporte',
      url: '/solicitud-transporte',
      icon: 'car-outline',
      allow: false,
      disabled: true,
    },
    {// 2
      title: 'Reportes',
      url: '/reportes-menu',
      icon: 'document-outline',
      allow: false,
      disabled: false,
    },
    {// 3
      title: 'Tarifas Periodo Usuarios',
      url: '/tarifas-periodo-usuarios',
      icon: 'calendar-outline',
      allow: false,
      disabled: false,
    },
    {// 4
      title: 'Tarifas Por Ocupación',
      url: '/tarifas-por-ocupacion',
      icon: 'calendar-outline',
      allow: true,
      disabled: false,
    },
    {// 5
      title: 'Configuración',
      url: '/configuracion',
      icon: 'cog-outline',
      allow: false,
      disabled: false,
    },
    // {
    //   title: 'Generar QR',
    //   url: 'generar-qr',
    //   icon: 'barcode',
    // allow: false,
    // },  
    {
      divider: 1,
      url: '',
      allow: true,
      disabled: false,
    },

    {
      title: 'Servicios/Amenidades',
      url: '/alta-servicios',
      icon: 'fast-food-outline',
      allow: false,
      disabled: false,
    },
    {
      title: 'Complejos/Hoteles',
      url: '/alta-complejos',
      icon: 'business-outline',
      allow: false,
      disabled: false,
    },
    {
      title: 'Tipo de Cuartos',
      url: '/alta-tipo-cuartos',
      icon: 'bed-outline',
      allow: false,
      disabled: false,
    },
    {
      title: 'Cuartos',
      url: '/alta-cuartos',
      icon: 'bed-sharp',
      allow: false,
      disabled: false,
    },
    // {
    //   title: 'Clientes',
    //   url: '/alta-clientes',
    //   icon: 'person-add-outline',
    //   allow: true,
    //   disabled: false,
    // },
    {
      title: 'Fechas Pago',
      url: '/alta-pagos',
      icon: 'calendar-outline',
      allow: false,
      disabled: false,
    },
    {
      title: 'Calcular Pago',
      url: '/calcular-pago',
      icon: 'cash-outline',
      allow: false,
      disabled: false,
    },
    {
      title: 'Politicas',
      url: '/politicas',
      icon: 'document-text',
      allow: false,
      disabled: false,
    },
    {
      title: 'Tipos Servicios',
      url: '/altas-tipos',
      icon: 'key',
      allow: false,
      disabled: true,
    },
    {
      title: 'Conductores',
      url: '/conductores',
      icon: 'person-outline',
      allow: false,
      disabled: true,
    },
    {
      title: 'Alta Formularios',
      url: '/alta-formularios',
      icon: 'document-outline',
      allow: false,
      disabled: true,
    },
    {
      title: 'Alta formulario dinámico',
      url: '/formulario-dinamico',
      icon: 'document-outline',
      allow: false,
      disabled: true,
    },
    {
      divider: 2,
      url: '',
      allow: true,
      disabled: false,
    },
    {
      title: 'Cambiar mi contraseña',
      url: '/change-password',
      icon: 'keypad',
      allow: true,
      disabled: false,
    },
    {
      title: 'Usuarios',
      url: '/usuarios-admin',
      icon: 'person-outline',
      allow: false,
      disabled: false,
    },
    {
      title: 'Registrar',
      url: '/register',
      icon: 'person-add-outline',
      allow: false,
      disabled: false,
    },
    {
      title: 'Cerrar Sesión',
      url: '/logout',
      icon: 'log-out-outline',
      allow: true,
      disabled: false,
    },
  ];

  guestPages = [
    { // 0
      title: 'Mis reservaciones',
      url: '/reservaciones',
      icon: 'key-outline',
      allow: true,
      disabled: false,
    },
    {
      divider: 2,
      url: '',
      allow: true,
      disabled: false,
    },

    {
      title: 'Cambiar mi contraseña',
      url: '/change-password',
      icon: 'keypad',
      allow: true,
      disabled: false,
    },
    {
      title: 'Cerrar Sesión',
      url: '/logout',
      icon: 'log-out-outline',
      allow: true,
      disabled: false,
    },
  ];

  autenticado: boolean;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthService,
    public firebaseService: FirebaseDataService,
    public userDataService: UserDataService,
    public config: ConfigService,
    public alertService: AlertsService,
  ) {
    config.LoadConfig();
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  async ngOnInit() {
    this.userDataService.appInstance = this;


    await this.AuthPersistence();
    // console.log(this.userDataService.appInstance);
    // await this.authService.GetCurrentUser();
    console.log('loading user');
    console.log('user data');
    // console.log("Usuario actual: ", await this.authService.GetCurrentUser());


    this.LoadPagePermissions();

    setTimeout(() => {
      this.FindSelectedPage();
    }, 1000);
  }

  FindSelectedPage() {
    // const path = '/' + window.location.pathname.split('/')[1];
    const path = '/' + window.location.href.split('#/')[1];
    if (path !== undefined) {
      if (this.userDataService.isAdmin) {
        this.selectedIndex = this.appPages.findIndex(page => page.url.toLowerCase() === path.toLowerCase());
      } else {
        this.selectedIndex = this.guestPages.findIndex(page => page.url.toLowerCase() === path.toLowerCase());
      }
    }
  }


  LoadPagePermissions() {
    // ? Permisos para consultar pagina
    if (this.userDataService.isLogged) {
      let i = 0;
      console.log('loading app permissions');

      i = this.appPages.findIndex(page => page.url.toLowerCase() === "/politicas");
      if (i !== -1) {
        this.appPages[i].allow = (this.userDataService?.permissions["politicas"][1]?.value || this.userDataService?.permissions["politicas"][5]?.value);
      }

      i = this.appPages.findIndex(page => page.url.toLowerCase() === "/reservaciones");
      if (i !== -1) {
        this.appPages[i].allow = this.userDataService.permissions["reservaciones"][1].value;
      }

      i = this.appPages.findIndex(page => page.url.toLowerCase() === "/solicitud-transporte");
      if (i !== -1) {
        this.appPages[i].allow = this.userDataService.permissions.solicitud_transportes[1].value;
      }

      i = this.appPages.findIndex(page => page.url.toLowerCase() === "/reportes-menu");
      if (i !== -1) {
        this.appPages[i].allow = this.userDataService.permissions["reportes"][1].value;
      }

      i = this.appPages.findIndex(page => page.url.toLowerCase() === "/tarifas-periodo-usuarios");
      if (i !== -1) {
        this.appPages[i].allow = this.userDataService.permissions["tarifas_periodo_usuarios"][1].value;
      }

      i = this.appPages.findIndex(page => page.url.toLowerCase() === "/tarifas-por-ocupacion");
      if (i !== -1) {
        this.appPages[i].allow = this.userDataService.permissions["tarifas_por_ocupacion"][1].value;
      }

      i = this.appPages.findIndex(page => page.url.toLowerCase() === "/configuracion");
      if (i !== -1) {
        this.appPages[i].allow = this.userDataService.permissions.configuraciones[1].value;
      }

      i = this.appPages.findIndex(page => page.url.toLowerCase() === "/alta-servicios");
      if (i !== -1) {
        this.appPages[i].allow = this.userDataService.permissions.servicios[1].value;
      }

      i = this.appPages.findIndex(page => page.url.toLowerCase() === "/alta-complejos");
      if (i !== -1) {
        this.appPages[i].allow = this.userDataService.permissions.complejos[1].value;
      }

      i = this.appPages.findIndex(page => page.url.toLowerCase() === "/alta-tipo-cuartos");
      if (i !== -1) {
        this.appPages[i].allow = this.userDataService.permissions.tipo_cuartos[1].value;
      }

      i = this.appPages.findIndex(page => page.url.toLowerCase() === "/alta-cuartos");
      if (i !== -1) {
        this.appPages[i].allow = this.userDataService.permissions.cuartos[1].value;
      }

      i = this.appPages.findIndex(page => page.url.toLowerCase() === "/altas-tipos");
      if (i !== -1) {
        this.appPages[i].allow = this.userDataService.permissions.tipo_servicios[1].value;
      }

      i = this.appPages.findIndex(page => page.url.toLowerCase() === "/conductores");
      if (i !== -1) {
        this.appPages[i].allow = this.userDataService.permissions.conductores[1].value;
      }

      i = this.appPages.findIndex(page => page.url.toLowerCase() === "/alta-formularios");
      if (i !== -1) {
        this.appPages[i].allow = this.userDataService.permissions.formularios[1].value;
      }

      i = this.appPages.findIndex(page => page.url.toLowerCase() === "/formulario-dinamico");
      if (i !== -1) {
        this.appPages[i].allow = this.userDataService.permissions.formularios_dinamicos[1].value;
      }

      i = this.appPages.findIndex(page => page.url.toLowerCase() === "/usuarios-admin");
      if (i !== -1) {
        this.appPages[i].allow = this.userDataService.permissions.usuarios_admin[1].value;
      }

      i = this.appPages.findIndex(page => page.url.toLowerCase() === "/register");
      if (i !== -1) {
        this.appPages[i].allow = this.userDataService.permissions.usuarios_admin[0].value;
      }

      i = this.appPages.findIndex(page => page.url.toLowerCase() === "/alta-clientes");
      if (i !== -1) {
        this.appPages[i].allow = this.userDataService.permissions.clientes[1].value;
      }

      i = this.appPages.findIndex(page => page.url.toLowerCase() === "/alta-pagos");
      if (i !== -1) {
        this.appPages[i].allow = this.userDataService.permissions.fechas_pago[1].value;
      }

      i = this.appPages.findIndex(page => page.url.toLowerCase() === "/calcular-pago");
      if (i !== -1) {
        this.appPages[i].allow = this.userDataService.permissions.calcular_pago[1].value;
      }



      this.GetActiveTurn();
    }
  }


  turnos: Turno[];
  // turnoActual: TurnoActivo;
  changingTurn = false;
  async GetActiveTurn() {
    this.turnos = await new Promise((resolve, reject) => {
      this.firebaseService.GetTurnos().subscribe(data => {
        if (data) {
          resolve(data);
        } else {
          console.log('no data turnos');
          resolve(null);
        }
      });
    });



    console.log('turnos: ', this.turnos);
    // var startNextTurnTime = moment(this.turnos[2].salida, [moment.ISO_8601, 'HH:mm:ss']).add(1, 'second').format('HH:mm:ss');
    // console.log('startNextTurnTime: ', startNextTurnTime);
    // let turnoActivo: TurnoActivo[] = await new Promise((resolve, reject) => {
    //   this.firebaseService.GetTurnoActivo().subscribe(data => {
    //     if (data) {
    //       resolve(data);
    //     } else {
    //       console.log('no data turnos activos');
    //       resolve(null);
    //     }
    //   });
    // });




    this.firebaseService.GetTurnoActivo().subscribe(async data => {

      if (data) {
        if (data.length > 0) {
          this.firebaseService.turnoActivo = data[0];
        } 
        // else {
        //   console.log('no hay turnos activos, CREANDO PRIMER TURNO');
        //   const currentTurn = this.turnos.find(t => t.orden == 1);
        //   let timeStamp = moment().format('YYYY-MM-DD HH:mm:ss') + ":" + moment().millisecond();
        //   this.firebaseService.turnoActivo = {
        //     createdAt: timeStamp,
        //     createdBy: this.userDataService.currentUser.id,
        //     estatus: estatusSistema.Activo,
        //     turnoActivo: true,
        //     turnoId: currentTurn.id,
        //     closedAt: ''
        //   };
        //   var ref = await this.firebaseService.AddTurnoActivo(this.firebaseService.turnoActivo);
        //   this.firebaseService.turnoActivo.id = ref.id;
        // }
      } else {
        console.log('no data turnos activos');
      }
      console.log('turno activo: ', this.firebaseService.turnoActivo);
    });

  }

  async ConfirmChangeTurn() {
    let timeNow = moment().format('HH:mm:ss');
    let order = this.turnos.find(t => t.id == this.firebaseService.turnoActivo.turnoId).orden;
    order++;
    if (order > this.turnos.length) {
      order = 1;
    }
    console.log('order: ', order);
    const nextTurn = this.turnos.find(t => t.orden == order);
    console.log('next: ', nextTurn);

    // let turnoAct = [];
    // turnoAct.push(this.firebaseService.turnoActivo);
    // let t = this.turnos.find(t => t.id == turnoAct[0]["turnoId"])

    const isDawnNextT = (nextTurn.inicio > nextTurn.salida);
    // if(isDawn){
    //   if (timeNow < nextTurn.inicio) {//ASI ESTABA ANTES
    //     let splittedTime = nextTurn.inicio.split(':');
    //     await this.alertService.PresentAlert('', `<ion-icon size="large" class="ion-text-center" name="information-circle-outline"></ion-icon><br>` + 'El turno no puede cerrarse antes del horario de incio del ' + nextTurn.nombre + ", inicio: " + splittedTime[0] + ":" + splittedTime[1] + " Hrs.");
    //     return;
    //   }
    // }
    // else
    if(isDawnNextT){
      if(timeNow >= nextTurn.inicio && timeNow <= "23:59:59" ||  timeNow >= "00:00:00" && timeNow <= nextTurn.salida){
        //continuar
      }
      else{
        let splittedTime = nextTurn.inicio.split(':');
        await this.alertService.PresentAlert('', `<ion-icon size="large" class="ion-text-center" name="information-circle-outline"></ion-icon><br>` + 'El turno no puede cerrarse antes del horario de incio del ' + nextTurn.nombre + ", inicio: " + splittedTime[0] + ":" + splittedTime[1] + " Hrs.");
        return;
      }
    }
    else if (timeNow < nextTurn.inicio) {
      let splittedTime = nextTurn.inicio.split(':');
      await this.alertService.PresentAlert('', `<ion-icon size="large" class="ion-text-center" name="information-circle-outline"></ion-icon><br>` + 'El turno no puede cerrarse antes del horario de incio del ' + nextTurn.nombre + ", inicio: " + splittedTime[0] + ":" + splittedTime[1] + " Hrs.");
      return;
    }

    this.changingTurn = true;
    console.log('turnoActual:', this.firebaseService.turnoActivo);

    const accept = await this.alertService.AlertConfirm('¿Cerrar el turno y cambiar al siguiente?', 'El turno actual es: ' + this.GetTurnoNombre(this.firebaseService.turnoActivo.turnoId), '');

    if (accept) {
      await this.alertService.presentLoading('Cambiando el turno');
      let timeStamp = moment().format('YYYY-MM-DD HH:mm:ss') + ":" + moment().millisecond();

      const clone = JSON.parse(JSON.stringify(this.firebaseService.turnoActivo)) as TurnoActivo;
      clone.turnoActivo = false;
      clone.closedAt = timeStamp;


      this.firebaseService.turnoActivo = {
        createdAt: timeStamp,
        createdBy: this.userDataService.currentUser.id,
        estatus: estatusSistema.Activo,
        turnoActivo: true,
        closedAt: '',
        turnoId: nextTurn.id,
      };

      var ref = await this.firebaseService.AddTurnoActivo(this.firebaseService.turnoActivo);
      this.firebaseService.turnoActivo.id = ref.id;

      await this.firebaseService.UpdateTurnoActivo(clone);

      if (nextTurn == undefined) {
        await this.alertService.presentToastWithCloseButton('No se encontró el turno activo, buscando el siguiente: ' + order);
      }

      this.revisarTurnosActivos();
      this.alertService.closeLoading();
      this.changingTurn = false;
    }
  }

  turnos_activos;
  async revisarTurnosActivos(){
    this.turnos_activos = [];
    this.turnos_activos = await new Promise((resolve, reject) => {
      this.firebaseService.GetTurnosActivos().subscribe(data => {
        if (data) {
          resolve(data);
        } else {
          console.log('no data turnos');
          resolve(null);
        }
      });
    });
    
    if(this.turnos_activos.length > 1){
      this.turnos_activos.sort(function (a, b) {
        var c = new Date(a.createdAt);
        var d = new Date(b.createdAt);
        return Number(d) - Number(c);
      });
      
      for (let i = 1; i < this.turnos_activos.length; i++) {
        const ta = this.turnos_activos[i];
        ta["turnoActivo"] = false;

        await this.firebaseService.UpdateTurnoActivo(ta);
      }
    }
  }

  CheckInfo() {
    console.log('turnoActual:', this.firebaseService.turnoActivo);
  }

  GetTurnoNombre(id: string) {
    if (this.turnos == undefined) {
      return '';
    } else {
      return this.turnos.find(t => t.id == id)?.nombre;
    }
  }

  Logout() {
    this.authService.Logout();
  }


  async AuthPersistence() {
    await this.authService.AuthPersistence();
    const user = await this.authService.GetCurrentUser();
    console.log('AUTH USER: ', { email: user.email, uid: user.uid });

    if (user?.uid !== undefined) {
      // ? IS ADMIN
      if (user?.email.includes(this.authService.accountSuffix)) {
        this.firebaseService.LoadAdminFirebase();
        console.log('AUTH loading firebase admin');
        const observable = await this.firebaseService.GetUserAdmin_ByUid(user.uid);
        const u = await this.WaitForData(observable) as UserAdmin;
        // console.log(u);
        if (u !== null && u !== undefined) {
          this.userDataService.SetUserAdminData(u);
          // this.idleLogout();
        }
      } else {
        const observable = await this.firebaseService.GetUserGuest_ByUid(user.uid);
        const u = await this.WaitForData(observable) as User;
        console.log('guest: ', u);
        if (u !== null && u !== undefined) {
          this.userDataService.SetUserGuestData(u);
          // this.idleLogout();
          this.firebaseService.LoadGuestFirebase(u.id);
          console.log('AUTH loading firebase client');
        } else {
          console.log('no user found');
        }
      }
    }
  }


  idleLogout() {
    var t;
    window.onload = resetTimer;
    window.onmousemove = resetTimer;
    window.onmousedown = resetTimer;  // catches touchscreen presses as well      
    window.ontouchstart = resetTimer; // catches touchscreen swipes as well 
    window.onclick = resetTimer;      // catches touchpad clicks as well
    window.onkeypress = resetTimer;
    window.addEventListener('scroll', resetTimer, true); // improved; see comments

    const scope = this;

    function yourFunction() {
      // your function for too long inactivity goes here
      // e.g. window.location.href = 'logout.php';
      console.log('log out');
      scope.authService.Logout();
    }

    function resetTimer() {
      clearTimeout(t);
      t = setTimeout(yourFunction, scope.config.loginTimeOut);  // time is in milliseconds
      // 600000
    }
  }



  WaitForData(observable: Observable<any>): Promise<any> {
    return new Promise((resolve, reject) => {
      observable.subscribe(data => {
        if (data) {
          resolve(data[0]);
        } else {
          console.log('no data');
          resolve(null);
        }
      });
    });
  }


}
